export const getHora = (turno, hora) => {
  // Si la hora de inicio es mayor a las 10am y el turno es mañana
  // entonces la hora de inicio es la hora de inicio

  if (hora >= "10:00" && turno === "Consulta Externa - Mañana") {
    return "10:00 am";
  }

  // Si la hora de inicio es menor a las 10am y el turno es mañana
  // entonces la hora de inicio es la hora de inicio

  if (hora < "10:00" && turno === "Consulta Externa - Mañana") {
    return "7:30 am";
  }

  if (turno === "Consulta Externa - Tarde") {
    return "2:00 pm";
  }

  return "";
};

const months = {
  "01": "Ene",
  "02": "Feb",
  "03": "Mar",
  "04": "Abr",
  "05": "May",
  "06": "Jun",
  "07": "Jul",
  "08": "Ago",
  "09": "Sep",
  10: "Oct",
  11: "Nov",
  12: "Dic",
};

export const getFormattedDate = (date) => {
  // '2023-03-01T00:00:00.000Z' ->'01-Mar'
  const dateParts = date.split("-");
  const month = dateParts[1];
  const day = dateParts[2].split("T")[0];
  return `${day}-${months[month]}`;
};

export const isDateBetween = (date, start, end) => {
  return date >= start && date <= end;
};

export const isCurrentDateBetweenFridayAndMonday = () => {
  const currentDate = new Date();
  const currentDay = currentDate.getDay();
  const currentTime = currentDate.getHours();

  // Establecer el viernes a las 7:00 PM
  const friday = new Date(currentDate);
  friday.setHours(19, 0, 0);
  friday.setDate(currentDate.getDate() + ((5 + 7 - currentDay) % 7));

  // Establecer el lunes a las 7:00 AM
  const monday = new Date(currentDate);
  monday.setHours(7, 0, 0);
  monday.setDate(currentDate.getDate() + ((1 + 7 - currentDay) % 7));

  // return isDateBetween(currentDate, friday, monday) && currentTime >= 19;
  return false;
};
