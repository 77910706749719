import { Column, Typography } from "core/util-styled-components";
import React from "react";
import CardSO from "../molecules/CardSO";

const PanelTrabajador = () => {
  return (
    <Column margin="20px 0" gap="15px">
      <Typography
        style={{
          fontWeight: 500,
        }}
      >
        Usted es un trabajador de HACSRII-2, puede agendar citas para:
      </Typography>
      <CardSO />
    </Column>
  );
};

export default PanelTrabajador;
