import React from "react";
import { Content } from "./styled";

const ContentComponent = ({ children, ...rest }) => {
  return (
    <Content bodyStyle={{ overflow: "auto" }} {...rest}>
      {children}
    </Content>
  );
};

export default ContentComponent;
