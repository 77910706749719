import { Button, Card } from "antd";
import { useMediaQueryCustom } from "core/hooks/useMediaQueryCustom";
import { Column, Row, Typography } from "core/util-styled-components";
import React from "react";

const CardEspecialidad = ({ medico, onClick, getDisponibilidad }) => {
  const { isTabletOrMobile } = useMediaQueryCustom();

  return (
    <Card hoverable key={medico.IdMedico} onClick={onClick}>
      <Row justifyContent="space-between">
        <Column>
          <Typography>
            <b>{medico.medico}</b> - {medico.Servicio}
          </Typography>
          {isTabletOrMobile && (
            <Typography>
              <b>{getDisponibilidad(medico.IdServicio, medico.IdMedico)}</b>{" "}
              citas disponibles
            </Typography>
          )}
        </Column>
        {getDisponibilidad(medico.IdServicio, medico.IdMedico) > 0 ? (
          <Row>
            {!isTabletOrMobile && (
              <Typography>
                <b>{getDisponibilidad(medico.IdServicio, medico.IdMedico)}</b>{" "}
                citas disponibles
              </Typography>
            )}
            <Button type="link">
              <i className="ri ri-arrow-right-line" />
            </Button>
          </Row>
        ) : (
          <Typography color="red">No hay cupos disponibles</Typography>
        )}
      </Row>
    </Card>
  );
};

export default CardEspecialidad;
