import { Button, notification } from "antd";
import Timer from "components/molecules/Timeout";
import ContentComponent from "components/skeleton/ContentComponent";
import { useReferenciaRefcon } from "core/hooks/ReferenciaContext";
import useAxios from "core/hooks/useAxios";
import { useMediaQueryCustom } from "core/hooks/useMediaQueryCustom";
import time from "core/util-functions/src/core/time";
import {
  Column,
  Grid,
  Heading,
  Row,
  Typography,
} from "core/util-styled-components";
import React, { useEffect, useState, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import { useLogged } from "core/hooks/LoggedContext";
import DiaCupo from "pages/ConsultaExterna/components/atoms/Cupo/DiaCupo";
import Cupo from "pages/ConsultaExterna/components/atoms/Cupo";

const ListaDisponibilidadCitasSO = () => {
  const { isTabletOrMobile } = useMediaQueryCustom();
  const [diaSeleccionado, setDiaSeleccionado] = useState(null);
  const [horaSeleccionado, setHoraSeleccionado] = useState(null);
  const [ocupadasCargadas, setOcupadasCargadas] = useState(false);
  const [mapDisponibilidad, setMapDisponibilidad] = useState([]);
  const { medicoSeleccionado, setMedicoSeleccionado } = useReferenciaRefcon();
  const nav = useNavigate();

  const { metadata } = useLogged();

  const ProgramacionBloqueadas = useAxios(
    "GET",
    "/api/listar-programaciones-bloqueadas"
  );
  const ListarDisponibilidadCupos = useAxios(
    "POST",
    "/api/consulta-externa/cantidad-citas-programacion",
    false,
    {
      nombre: "Salud Ocupacional",
    }
  );

  const GenerateCita = useAxios(
    "POST",
    "/api/consulta-externa/generar-cita/galenhos",
    true
  );

  const validaSiEstaLleno = (idProgramacion) => {
    const programacion = ListarDisponibilidadCupos.datos;

    const find = programacion.find(
      (item) => item.IdProgramacion === idProgramacion
    );

    if (find) {
      return find.CantidadCitas >= find.totalCitas;
    }
    return false;
  };

  const Disponibilidad = useAxios(
    "POST",
    "/api/consulta-externa/programacion-medica/medico",
    true
  );

  const ListarOcupadas = useAxios(
    "POST",
    "/api/consulta-externa/programacion-medica/listar-ocupados",
    true
  );

  useEffect(() => {
    if (ListarOcupadas.respuestaServer === 200) {
      setOcupadasCargadas(true);
    } else {
      setOcupadasCargadas(false);
    }

    return () => {};
  }, [ListarOcupadas.respuestaServer]);

  useEffect(() => {
    diaSeleccionado && getHorasOcupadas(diaSeleccionado?.IdProgramacion);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diaSeleccionado]);

  useEffect(() => {
    if (GenerateCita.respuestaServer === 200) {
      if (!Array.isArray(GenerateCita.datos) && GenerateCita.datos?.error) {
        return notification.error({
          message: GenerateCita.datos.message,
          placement: "topRight",
          duration: 2.5,
          key: "error",
        });
      }

      notification.success({
        message: "Cita generada exitosamente",
        placement: "topRight",
        duration: 2.5,
        key: "success",
      });
      // leaveRoom();
      nav("/consulta-externa/mis-citas");
    }
    if (GenerateCita.respuestaServer === 500) {
      notification.error({
        message: "Error al generar cita",
        style: {
          fontSize: 10,
        },
        placement: "topRight",
        duration: 2.5,
        key: "error",
      });
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GenerateCita]);

  useEffect(() => {
    if (medicoSeleccionado) {
      Disponibilidad.actualizarParametros({
        idMedico: medicoSeleccionado?.IdMedico,
        idServicio: medicoSeleccionado?.IdServicio,
      });

      Disponibilidad.iniciarPeticion();
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [medicoSeleccionado]);

  useEffect(() => {
    if (!medicoSeleccionado) {
      nav("/consulta-externa/agendar-cita");
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [medicoSeleccionado]);

  const generarMapeoDisponibilidad = (inicio, fin, tiempoAñadir) => {
    const mapeo = [];
    let horaActual = inicio;

    // Calcula la diferencia en minutos entre inicio y fin
    const diferenciaMinutos =
      (new Date(`01/01/1999 ${fin}:00`).getTime() -
        new Date(`01/01/1999 ${inicio}:00`).getTime()) /
      (60 * 1000);

    // Calcula la cantidad de horarios a generar
    const cantidadHorarios = Math.ceil(diferenciaMinutos / tiempoAñadir);

    for (let i = 0; i < cantidadHorarios; i++) {
      const horaFin = time.sumarTiempo(horaActual, tiempoAñadir); // Calcula la hora de fin del intervalo
      mapeo.push({ hora: horaActual, horaFin }); // Agrega el intervalo al mapeo
      horaActual = horaFin; // Actualiza la hora actual para el siguiente intervalo
    }
    const size = mapeo.length;

    // Calcula el tamaño de cada grupo
    const groupSize1 = Math.ceil(size / 3);
    const groupSize2 = Math.floor(size / 3);

    const primeraParte = mapeo.slice(0, groupSize1);
    const segundaParte = mapeo.slice(groupSize1, groupSize1 + groupSize2);
    // const terceraParte = mapeo.slice(groupSize1 + groupSize2);

    return [
      { name: "Primera atención", cupos: primeraParte },
      { name: "Reevaluación", cupos: segundaParte },
      // { name: "Teleconsulta", cupos: terceraParte },
    ];
  };

  const agendarCita = () => {
    const params = {
      IdProgramacion: diaSeleccionado?.IdProgramacion,
      nroDNI: metadata?.DataSIGH?.nroDocumento,
      idpaciente: metadata?.DataSIGH?.IdPaciente,
      cita: {
        fechaCita: diaSeleccionado?.Fecha,
        horaCita: horaSeleccionado?.hora,
        horaCitaFin: horaSeleccionado?.horaFin,
        // referencia: referenciaRefcon?.nroreferencia,
      },
      ff: 4, // Tipo financiamiento ESSALUD
    };
    GenerateCita.actualizarParametros(params);
    GenerateCita.iniciarPeticion();
  };

  const getHorasOcupadas = (idProgramacion) => {
    ListarOcupadas.actualizarParametros({
      idProgramacion,
    });
    ListarOcupadas.iniciarPeticion();
  };

  // determinar si el boton de seleccion de hora esta deshabilitado
  const getDisabled = (item) => {
    if (!ocupadasCargadas) {
      return true;
    }

    if (!Array.isArray(ListarOcupadas.datos)) {
      return true;
    }

    const ocupadas = ListarOcupadas.datos?.filter(
      (ocupada) =>
        ocupada.HoraInicio === item.hora && ocupada.HoraFin === item.horaFin
    );

    return ocupadas.length > 0;
  };

  const getInSelection = (id) => {
    if (!Array.isArray(ProgramacionBloqueadas.datos)) {
      return false;
    }

    if (diaSeleccionado?.IdProgramacion === id) {
      return false;
    }

    const filter = ProgramacionBloqueadas.datos.filter(
      (el) => el.IdProgramacion === id
    );

    return filter.length > 0;
  };

  useEffect(() => {
    if (diaSeleccionado) {
      setMapDisponibilidad(
        generarMapeoDisponibilidad(
          diaSeleccionado?.HoraInicio,
          diaSeleccionado?.HoraFinProgramacion,
          diaSeleccionado?.TiempoPromedioAtencion
        )
      );
    } else {
      setMapDisponibilidad([]);
    }

    return () => {};
  }, [diaSeleccionado]);

  return (
    <ContentComponent>
      <Column gap="15px">
        <Row>
          <Button
            type="text"
            onClick={() => {
              setMedicoSeleccionado(null);
              nav("/consulta-externa/agendar-cita-so");
            }}
          >
            <i className="ri ri-arrow-left-line"></i>
          </Button>
          <Heading variant="h3" style={{ margin: 0 }}>
            Programacion médica
          </Heading>
        </Row>

        <Typography variant="h3">
          Seleccione el día en el que desea agendar su cita
        </Typography>

        <Typography>
          Agendar una cita con Dr(a) <b>{medicoSeleccionado?.medico}</b> -{" "}
          {medicoSeleccionado?.Servicio}
        </Typography>
        <Column
          gap="15px"
          style={{ height: "55vh", overflowY: "auto", overflowX: "hidden" }}
        >
          <Typography>
            Seleccione el día en el que desea agendar su cita
          </Typography>
          <Grid cols={isTabletOrMobile ? 2 : 4} gap="10px">
            {Array.isArray(Disponibilidad.datos) &&
              Disponibilidad.datos.map((item, index) => {
                return (
                  <DiaCupo
                    item={item}
                    value={diaSeleccionado}
                    onChange={() => {
                      if (diaSeleccionado) {
                        // desbloquearProgramacion(diaSeleccionado.IdProgramacion);
                      }
                      setDiaSeleccionado(item);
                      // bloquearProgramacion(item.IdProgramacion);
                      ProgramacionBloqueadas.recargarPeticion();
                      setHoraSeleccionado(null);
                    }}
                    isBloqued={
                      getInSelection(item.IdProgramacion)
                      // || isBloquedCase1(item)
                    }
                    isCollapse={validaSiEstaLleno(item.IdProgramacion)}
                  />
                );
              })}
          </Grid>
          {diaSeleccionado && (
            <Column justifyContent="space-between">
              <Typography>
                Seleccione el cupo en el que desea agendar su cita para el turno
                de <b>{diaSeleccionado?.HoraInicio}</b> a{" "}
                <b>{diaSeleccionado?.HoraFin}</b> en{" "}
                <b>{diaSeleccionado?.Turno}</b>
              </Typography>

              <Heading>
                Tiempo restante para seleccionar el cupo{" "}
                <Timer
                  onTimerComplete={() => {
                    if (diaSeleccionado) {
                      // desbloquearProgramacion(diaSeleccionado.IdProgramacion);
                      setDiaSeleccionado(null);
                      ProgramacionBloqueadas.recargarPeticion();
                    }
                  }}
                />
              </Heading>
            </Column>
          )}
          <Column gap="10px">
            {mapDisponibilidad.map((tipo, index) => {
              return (
                <Fragment key={index}>
                  <Heading variant="h2">{tipo.name}</Heading>
                  <Grid cols={isTabletOrMobile ? 1 : 4} gap="10px">
                    {tipo.cupos.map((item) => {
                      return (
                        <Cupo
                          diaSeleccionado={diaSeleccionado}
                          item={item}
                          value={horaSeleccionado}
                          onChange={() => setHoraSeleccionado(item)}
                          isDisabled={getDisabled(item)}
                          defaultHours={"09:00 am"}
                        />
                      );
                    })}
                  </Grid>
                </Fragment>
              );
            })}
          </Column>
        </Column>
        <Row responsiveReorder justifyContent="space-between">
          <Typography variant="label">
            Por favor, tenga en cuenta que: - La cita se agendará para el día
            que usted seleccione.
            <span style={{ color: "red" }}>
              - Las citas adicionales se agendan presencialmente, previa
              coordinación con su jefatura.
            </span>
          </Typography>
          <Button
            type="primary"
            disabled={horaSeleccionado === null}
            onClick={() => {
              agendarCita();
            }}
          >
            Guardar cita
          </Button>
        </Row>
      </Column>
    </ContentComponent>
  );
};

export default ListaDisponibilidadCitasSO;
