import React, { useMemo } from "react";
import socket from "./socket";

const RealTimeContext = React.createContext();

export function RealTimeProvider(props) {
  const value = useMemo(() => {
    const Emit = (event, data) => {
      socket.emit(event, data);
    };
    return {
      socket,
      Emit,
    };
    //eslint-disable-next-line
  }, [socket]);

  return <RealTimeContext.Provider value={value} {...props} />;
}

export function useRealTimeContext() {
  const context = React.useContext(RealTimeContext);
  if (!context) {
    throw new Error("No se encontro socket en el contexto");
  }
  return context;
}
