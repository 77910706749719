import { parseEspecialidadNombre } from 'core/utils/shader'
import React, { useMemo, useState } from 'react'

const ReferenciaContext = React.createContext()
export function ReferenciaProvider (props) {
  const [referenciaRefcon, setReferenciaRefcon] = useState(null)
  const [medicoSeleccionado, setMedicoSeleccionado] = useState(null)
  const [isReferenciaHistorica, setIsReferenciaHistorica] = useState(false)
  const [restriccion_caso1, setRestriccion_caso1] = useState(false)

  const value = useMemo(() => {
    return {
      referenciaRefcon,
      medicoSeleccionado,
      setReferenciaRefcon: refcon => {
        setReferenciaRefcon(
          refcon
            ? {
                ...refcon,
                // extraer solo el primer nombre de especialidad
                especialidad: parseEspecialidadNombre(refcon?.especialidad)
              }
            : null
        )
      },
      restriccion_caso1,
      setRestriccion_caso1,
      setMedicoSeleccionado,
      isReferenciaHistorica,
      setIsReferenciaHistorica
    }
    //eslint-disable-next-line
  }, [referenciaRefcon, medicoSeleccionado, isReferenciaHistorica])

  return <ReferenciaContext.Provider value={value} {...props} />
}
export function useReferenciaRefcon () {
  const context = React.useContext(ReferenciaContext)
  if (!context) {
    throw new Error('No se encontro Usuario en el contexto')
  }
  return context
}
