import React, { useEffect, useState } from "react";
import { Column, Heading, Row, Typography } from "core/util-styled-components";
import ContentComponent from "components/skeleton/ContentComponent";
import { Spin } from "antd";
import { useReferenciaRefcon } from "core/hooks/ReferenciaContext";
import { useNavigate } from "react-router-dom";
import useAxios from "core/hooks/useAxios";
import { useLogged } from "core/hooks/LoggedContext";
import CardReferencia from "./components/molecules/CardReferencia";
import NoDisponibleView from "./components/organisms/NoDisponibleView";
import { isCurrentDateBetweenFridayAndMonday } from "./utils/ce_util";
import PanelTrabajador from "./components/organisms/PanelTrabajador";

const GOP = [
  "ONCOLOGÍA",
  "ONCOLOGIA",
  "INFECTOLOGÍA",
  "INFECTOLOGIA",
  "NEUROPEDIATRÍA",
  "NEUROPEDIATRIA",
  "ODONTOLOGÍA",
  "ODONTOLOGIA",
  "PEDIATRÍA - NEUROLOGÍA PEDIÁTRICA",
  "CIRUGÍA ONCOLÓGICA",
  "MEDICINA GENERAL",
  "MEDICINA ONCOLÓGICA", // solo dejo medicina interna e intensiva
  "PSICOLOGÍA",
  "UROLOGÍA GENERAL Y ONCOLOGÍA",
  "REUMATOLOGIA",
  "REUMATOLOGÍA",
];

const PanelSIS = () => {
  const isBetweenFridayAndMonday = isCurrentDateBetweenFridayAndMonday();

  const { metadata } = useLogged();
  const { setReferenciaRefcon, setIsReferenciaHistorica } = useReferenciaRefcon();

  const [isSisPaciente, setIsSisPaciente] = useState(false);

  const Referencias = useAxios("GET", "/api/refcon-dni/" + (metadata?.DataSIGH?.nroDocumento === "75566300" ? "02642280" : metadata?.DataSIGH?.nroDocumento));
  const ReferenciasHistorico = useAxios("GET", "/api/refcon-dni-historico/" + (metadata?.DataSIGH?.nroDocumento === "75566300" ? "02642280" : metadata?.DataSIGH?.nroDocumento));

  useEffect(() => {
    if (metadata && metadata.ResponseSIS) {
      setIsSisPaciente(metadata.ResponseSIS.code === 203 || metadata.ResponseSIS.code === 204);
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metadata]);

  const nav = useNavigate();

  const isDisponible = (descupsdestino) => {
    // validar si es una especialidad que no está disponible
    // por ejemplo: oncología, infectología, etc. no están disponibles
    const isDisponible =
      GOP.filter((item) => {
        return descupsdestino.includes(item);
      }).length === 0;
    return isDisponible;
  };

  return (
    <ContentComponent title={!isBetweenFridayAndMonday && <Heading variant="h2">Lista de referencias médicas {metadata?.isWorker ? "& Salud Ocupacional" : ""}</Heading>}>
      {isBetweenFridayAndMonday && <NoDisponibleView />}
      {!isBetweenFridayAndMonday && (
        <div>
          {!isSisPaciente && (
            <Row gap="15px">
              <Typography>
                {metadata.ResponseSIS?.code === 410 && metadata.ResponseSIS.message}

                {metadata.ResponseSIS?.code !== 410 && "Usted no es un paciente SIS, por lo tanto no puede generar citas."}
              </Typography>
            </Row>
          )}

          {Referencias.datos.total + ReferenciasHistorico.datos.total === 0 && isSisPaciente && (
            <Row gap="15px">
              <Typography>No tiene referencias disponibles para generar citas.</Typography>
            </Row>
          )}

          {isSisPaciente && Referencias.cargando && (
            <Row gap="15px">
              <Spin /> Buscando referencias, validando si es un paciente SIS...
            </Row>
          )}

          {isSisPaciente && (
            <Column gap="10px">
              {Referencias.datos.total > 0 && (
                <Row gap="15px">
                  <Typography
                    style={{
                      fontWeight: 500,
                    }}
                  >
                    Refencias nuevas aceptadas por el hospital de destino
                  </Typography>
                </Row>
              )}
              {Referencias.datos.items?.map((item) => {
                return (
                  <CardReferencia
                    item={item}
                    isDisponible={isDisponible}
                    onClick={() => {
                      setReferenciaRefcon(item);
                      setIsReferenciaHistorica(false);
                      // nav("/consulta-externa/sala-de-espera");
                      nav("/consulta-externa/agendar-cita");
                    }}
                  />
                );
              })}

              {ReferenciasHistorico.datos.total > 0 && (
                <Row gap="15px">
                  <Typography
                    style={{
                      fontWeight: 500,
                    }}
                  >
                    Referencias con cita previa ( seguir tratamiento )
                  </Typography>
                </Row>
              )}

              {ReferenciasHistorico.datos.items?.map((item) => {
                return (
                  <CardReferencia
                    item={item}
                    isDisponible={isDisponible}
                    isHistorico={true}
                    onClick={() => {
                      setReferenciaRefcon(item);
                      setIsReferenciaHistorica(true);
                      nav("/consulta-externa/agendar-cita");
                    }}
                  />
                );
              })}
            </Column>
          )}
        </div>
      )}
      {metadata?.isWorker && <PanelTrabajador />}
    </ContentComponent>
  );
};

export default PanelSIS;
