import Layout from "components/skeleton/Layout";
import AgendarCita from "pages/ConsultaExterna/AgendarCita";
import PanelSIS from "pages/ConsultaExterna/PanelSIS";
import ListaDisponibilidadCitas from "pages/ConsultaExterna/ListaDisponibilidadCitas";
import MisCitas from "pages/ConsultaExterna/MisCitas";
// import SolicitarCita from "pages/ConsultaExterna/SolicitarCita";
import Home from "pages/Home";
// import Login from "pages/Home/Login";
import LoginReniec from "pages/Home/LoginReniec";
// import RegistarUsuario from "pages/Home/RegistarUsuario";
// import MisDatosContacto from "pages/MisDatos/MisDatosContacto";
// import MisDatosEmergencia from "pages/MisDatos/MisDatosEmergencia";
import MisDatosPersonales from "pages/MisDatos/MisDatosPersonales";
// import MisDatosSalud from "pages/MisDatos/MisDatosSalud";
import DiagnosticoImagenes from "pages/MisResultados/DiagnosticoImagenes";
import LaboratorioClinico from "pages/MisResultados/LaboratorioClinico";
import DetalleEstudio from "pages/MisResultados/LaboratorioClinico/DetalleEstudio";
import TestIO from "pages/TestIO";
import { createHashRouter } from "react-router-dom";
import AgendarCitaSO from "pages/ConsultaExterna/AgendarCitaSO";
import ListaDisponibilidadCitasSO from "pages/ConsultaExterna/ListaDisponibilidadCitasSO";

const addLayout = (element) => {
  return <Layout>{element}</Layout>;
};

const generatePrivateRoutes = (routes) => {
  return routes.map((route) => {
    if (route.children) {
      return {
        ...route,
        children: generatePrivateRoutes(route.children),
      };
    }
    return {
      ...route,
      element: addLayout(route.element),
    };
  });
};

const Routesx = createHashRouter([
  {
    path: "/Login",
    element: <LoginReniec />,
  },
  // {
  //   path: "/RegistarUsuario",
  //   element: <RegistarUsuario />,
  // },
  ...generatePrivateRoutes([
    {
      path: "/",
      element: <Home />,
    },
    {
      path: "/test-io",
      element: <TestIO />,
    },
    {
      path: "consulta-externa",
      children: [
        {
          path: "mis-citas",
          element: <MisCitas />,
        },
        {
          path: "generar-cita-sis",
          element: <PanelSIS />,
        },
        {
          path: "agendar-cita-so",
          children: [
            { index: true, element: <AgendarCitaSO /> },
            {
              path: "seleccionar-fecha",
              element: <ListaDisponibilidadCitasSO />,
            },
          ],
        },
        {
          path: "agendar-cita",
          children: [
            {
              index: true,
              element: <AgendarCita />,
            },
            {
              path: "seleccionar-fecha",
              element: <ListaDisponibilidadCitas />,
            },
          ],
        },
      ],
    },
    {
      path: "mis-resultados",
      children: [
        {
          path: "diagnostico-por-imagenes",
          element: <DiagnosticoImagenes />,
        },
        {
          path: "laboratorio-clinico",
          children: [
            {
              index: true,
              element: <LaboratorioClinico />,
            },
            {
              path: ":id",
              element: <DetalleEstudio />,
            },
          ],
        },
      ],
    },
    {
      path: "mis",
      children: [
        {
          path: "datos-personales",
          element: <MisDatosPersonales />,
        },
      ],
    },
  ]),
]);

export default Routesx;
