import { Button, Card, Tag } from "antd";
import ContentComponent from "components/skeleton/ContentComponent";
import { Content } from "components/skeleton/styled";
import useAxios from "core/hooks/useAxios";
// import { useUsuario } from "core/hooks/UNUSED_UsuarioContext";
import {
  Column,
  Div,
  Heading,
  Row,
  Typography,
} from "core/util-styled-components";
import Ticket from "exports/ticket";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { useLogged } from "core/hooks/LoggedContext";
import { useMediaQueryCustom } from "core/hooks/useMediaQueryCustom";
import { Theme } from "theme/Theme";

const MisCitas = () => {
  const { metadata } = useLogged();
  const { isTabletOrMobile } = useMediaQueryCustom();
  const CitasAgendadas = useAxios(
    "GET",
    "/api/consultas/" + metadata?.DataSIGH?.nroDocumento
  );

  useEffect(() => {
    CitasAgendadas.actualizarUrl(
      "/api/consultas/" + metadata?.DataSIGH?.nroDocumento
    );
    CitasAgendadas.iniciarPeticion();

    return () => {};

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metadata]);

  return (
    <ContentComponent title={<Heading variant="h2">Mis Citas</Heading>}>
      <Column gap="1em">
        <Typography>
          Se muestran las citas agendadas en el modulo de{" "}
          <Link to="/consulta-externa/generar-cita-sis">Generar Cita</Link> y
          las citas que se han agendado en el sistema de citas del hospital.
        </Typography>
        <Heading variant="h2" color={Theme.colors.complementary}>
          Descarga tus tickets de cita
        </Heading>
        {Array.isArray(CitasAgendadas.datos) &&
        CitasAgendadas.datos.length > 0 ? (
          CitasAgendadas.datos.map((item, index) => {
            return (
              <Card
                title={<Typography>{item.ESPECIALIDAD}</Typography>}
                extra={
                  <Typography>
                    <strong>Fecha:</strong> {item.FECHA}
                  </Typography>
                }
              >
                <Row
                  justifyContent={"space-between"}
                  responsiveReorder
                  gap={isTabletOrMobile ? "1em" : "2em"}
                  alignItems={isTabletOrMobile ? "flex-start" : "center"}
                >
                  <Column alignItems="flex-start">
                    <Typography>
                      <strong>Financiamiento:</strong>{" "}
                      {item.FUENTEFINANCIAMIENTO}
                    </Typography>
                    {item.ORDENPAGO !== 0 && (
                      <Typography>
                        <strong>Orden de pago:</strong> {item.ORDENPAGO}
                      </Typography>
                    )}
                    <Typography>
                      <strong>Turno:</strong> {item.TURNO}
                    </Typography>
                    <Typography>
                      <strong>Doctor:</strong> {item.MEDICO}
                    </Typography>
                  </Column>
                  <Button
                    type="primary"
                    onClick={async () => {
                      await Ticket(item.CUENTA);
                    }}
                    size="large"
                    style={{
                      width: isTabletOrMobile ? "100%" : "auto",
                    }}
                  >
                    <Row gap="10px" justifyContent="center">
                      <i class="ri-download-cloud-2-line"></i>
                      <Heading
                        variant="h3"
                        color="inherit"
                        style={{ margin: 0, padding: 0 }}
                      >
                        Descargar TICKET
                      </Heading>
                    </Row>
                  </Button>
                </Row>
              </Card>
            );
          })
        ) : (
          <Card>
            <Typography>No se han encontrado citas agendadas</Typography>
          </Card>
        )}
      </Column>
    </ContentComponent>
  );
};

export default MisCitas;
