import React from "react";
import { LogoStyled } from "./styled";
import LOGOHSRP from "assets/LOGOHSRP.png";
// import { useUsuario } from "hooks/ContextUser";
import { useNavigate } from "react-router-dom";
const Logo = ({ reverse }) => {
  const nav = useNavigate();

  const handleClickLogo = () => {
    nav("/");
  };

  return (
    <LogoStyled reverse={reverse} onClick={handleClickLogo}>
      <img src={LOGOHSRP} alt="..." />
      <h3>Hospital de la Amistad Perú Corea Santa Rosa II-2</h3>
    </LogoStyled>
  );
};

export default Logo;
