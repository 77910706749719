import axios from "axios";
import AxiosTokenProvider from "axios-token-interceptor";
import { HOST_API, PARAMS_HOST } from "core/host";
const url = HOST_API;
export const fetchPost = async (uri, params, tokenInterceptor = "") => {
  const _itc = createInstance(url, tokenInterceptor);
  try {
    return await _itc.post(PARAMS_HOST, {
      method: "POST",
      route: uri,
      params,
    });
  } catch (error) {
    throw error;
  }
};

export const fetchGET = async (uri, tokenInterceptor = "") => {
  const _itc = createInstance(url, tokenInterceptor);
  try {
    return await _itc.post(PARAMS_HOST, {
      method: "GET",
      route: uri,
    });
  } catch (error) {
    throw error;
  }
};

export const createInstance = (baseURL, tokenInterceptor) => {
  const instance = axios.create({
    baseURL,
  });
  if (tokenInterceptor) {
    instance.interceptors.request.use(
      AxiosTokenProvider({
        getToken: () => tokenInterceptor,
      })
    );
  }
  return instance;
};
