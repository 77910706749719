import { Button, Card, Tag } from 'antd'
import { useReferenciaRefcon } from 'core/hooks/ReferenciaContext'
import useAxios from 'core/hooks/useAxios'
import { useMediaQueryCustom } from 'core/hooks/useMediaQueryCustom'
import { Column, Heading, Typography } from 'core/util-styled-components'
import React, { useEffect, useState } from 'react'

const CardReferencia = ({ item, isDisponible, onClick, isHistorico }) => {
  const [tieneCita, setTieneCita] = useState(false)
  const { setRestriccion_caso1 } = useReferenciaRefcon()
  const { isTabletOrMobile } = useMediaQueryCustom()

  const RevisarDisponibilidad = useAxios(
    'POST',
    '/api/consulta-externa/revisar-citas-por-nro-referencia-v2',
    false,
    {
      referencia: item.nroreferencia,
      nombre: item.especialidad
    }
  )

  useEffect(() => {
    if (item.nroreferencia) {
      RevisarDisponibilidad.actualizarParametros({
        referencia: item.nroreferencia,
        nombre: item.especialidad
      })
      RevisarDisponibilidad.iniciarPeticion()
    }
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [item])

  useEffect(() => {
    if (!Array.isArray(RevisarDisponibilidad.datos)) {
      setTieneCita(!RevisarDisponibilidad.datos.disponibleParaCita)
      setRestriccion_caso1(RevisarDisponibilidad.datos.caso === 'CASO_1')
    }
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [RevisarDisponibilidad.datos])

  return (
    <Card
      title={
        <Typography>
          Nro de Referencia: <Tag color='blue'>{item.nroreferencia}</Tag>
        </Typography>
      }
      extra={
        !isTabletOrMobile && (
          <Typography>
            Fecha de Referencia: <Tag>{item.fechaaceptacion}</Tag>
          </Typography>
        )
      }
      style={{
        borderBottomLeftRadius: '0'
      }}
    >
      <Column>
        {isTabletOrMobile && (
          <Typography>
            Fecha de Referencia: <Tag>{item.fechaaceptacion}</Tag>
          </Typography>
        )}
        <Typography style={{ fontWeight: '500' }}>
          Referido desde:{' '}
          <Typography style={{ fontWeight: '400' }}>
            {item.nombestorigen} {item.descupsdestino}
          </Typography>
        </Typography>

        <Typography style={{ fontWeight: '500' }}>
          Referido a:{' '}
          <Typography style={{ fontWeight: '400' }}>
            {item.nombestdestino}
          </Typography>
        </Typography>
      </Column>
      <Column alignItems='flex-end'>
        {!isDisponible(item.descupsdestino) && (
          <Typography
            color='red'
            style={{
              marginTop: isTabletOrMobile ? '2em' : '-2em'
            }}
          >
            Esta especialidad no está disponible.
          </Typography>
        )}
        {
          // si tiene cita, no mostrar el botón
          tieneCita && (
            <Heading
              variant='h3'
              color='green'
              style={{
                marginTop: isTabletOrMobile ? '2em' : '0em'
              }}
            >
              Ya tiene una cita agendada para esta referencia.
            </Heading>
          )
        }
        {!tieneCita && isDisponible(item.descupsdestino) && (
          <Button
            type='primary'
            size='large'
            style={{
              marginTop: isTabletOrMobile ? '2em' : '-2em',
              width: isTabletOrMobile ? '100%' : 'auto'
            }}
            onClick={onClick}
          >
            <Heading
              variant='h3'
              style={{
                margin: 0,
                padding: 0
              }}
            >
              Agendar cita
            </Heading>
          </Button>
        )}
      </Column>
    </Card>
  )
}

export default CardReferencia
