export const HomeMenu = [
  {
    header: "Consulta Externa",
  },
  {
    title: "Mis citas",
    icon: "calendar-line",
    path: "/consulta-externa/mis-citas",
  },
  {
    title: "Generar cita",
    icon: "calendar-2-line",
    path: "/consulta-externa/generar-cita-sis",
  },
  {
    header: "Mis resultados",
  },
  {
    title: "Diagnostico por imagenes",
    icon: "file-damage-line",
    path: "/mis-resultados/diagnostico-por-imagenes",
  },
  {
    title: "Laboratorio clinico",
    icon: "test-tube-line",
    path: "/mis-resultados/laboratorio-clinico",
  },
  {
    header: "Mis datos",
  },
  {
    title: "Perfil de usuario",
    icon: "user-line",
    path: "/mis/datos-personales",
  },
];
