import { Button, Card, Modal, Tag } from "antd";
import { useMediaQueryCustom } from "core/hooks/useMediaQueryCustom";
import { Column, Row, Typography } from "core/util-styled-components";
import { InformeDXPDF } from "exports/InformeDX";
import React, { useState } from "react";

export const ColorTag = {
  "Ecografía General": "#FF4F76",
  "Mamografia I (CE)": "#F39F10",
  "Densitometria (CE)": "#D81B1B",
  "Rayos X": "#8D13CE",
  Tomografía: "#CE5D13",
  "Proc-Gastroenteologia I (CE)": "#219900",
};

const Informe = ({ _ }) => {
  const [visible, setVisible] = useState(false);
  const { isTabletOrMobile } = useMediaQueryCustom();

  const PuntoCarga = () => (
    <Tag color={ColorTag[_.PuntoCarga.trim()] || "blue-inverse"}>
      {_.PuntoCarga.trim()}
    </Tag>
  );

  return (
    <Card
      title={<Typography>{_.examen}</Typography>}
      extra={
        !isTabletOrMobile && (
          <Tag>
            Registrado por:{" "}
            <Typography color="#838383">{_.NombresMedico}</Typography>
          </Tag>
        )
      }
    >
      <Row
        justifyContent="space-between"
        responsiveReorder
        width="100%"
        gap=".5em"
        alignItems={isTabletOrMobile ? "flex-start" : "center"}
      >
        <Column gap=".5em" alignItems="flex-start">
          {isTabletOrMobile && (
            <Typography>
              <strong>Medico que informa:</strong> {_.ApellidoMedico}{" "}
              {_.NombresMedico}
            </Typography>
          )}
          <Row gap="10px">
            <Typography>
              <strong>Punto de carga:</strong>
            </Typography>
            <PuntoCarga />
          </Row>
          <Typography>
            <strong>Fecha del examen:</strong> {_.fechaexamen.split("T")[0]}
          </Typography>
        </Column>
        <Column
          gap=".5em"
          alignItems="flex-start"
          responsiveReorder
          style={{ width: isTabletOrMobile ? "100%" : "auto" }}
        >
          <Button
            type="primary"
            style={{ width: "100%" }}
            onClick={() => {
              setVisible(true);
            }}
          >
            <Typography>Ver informe</Typography>
          </Button>
          <Button
            type="dashed"
            style={{ width: "100%" }}
            onClick={() => {
              InformeDXPDF({ informe: _.informe, idusuario: 9 });
            }}
          >
            <Typography color="inherit">Descargar informe</Typography>
          </Button>
        </Column>
      </Row>
      <Modal
        title={<Typography>Informe</Typography>}
        visible={visible}
        onCancel={() => {
          setVisible(false);
        }}
        footer={null}
        bodyStyle={{
          maxHeight: "72vh",
          overflowY: "auto",
          overflowX: "hidden",
          padding: "0",
          margin: "0",
        }}
      >
        <Typography>
          <div dangerouslySetInnerHTML={{ __html: _.informe }}></div>
        </Typography>
      </Modal>
    </Card>
  );
};

export default Informe;
