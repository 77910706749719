import useAxios from "core/hooks/useAxios";
import { useNavigate } from "react-router-dom";
import ContentComponent from "components/skeleton/ContentComponent";
import { useEffect, useState } from "react";
import { Button, Collapse, Spin, Tag } from "antd";
import { useReferenciaRefcon } from "core/hooks/ReferenciaContext";
import { Column, Heading, Row, Typography } from "core/util-styled-components";
import { useLogged } from "core/hooks/LoggedContext";
import Interconsultas from "./components/organisms/Interconsultas";
import CardEspecialidad from "./components/molecules/CardEspecialidad";
import { useMediaQueryCustom } from "core/hooks/useMediaQueryCustom";

const ExcludeList = {
  CIRUGÍA: [
    "Cirugía Pediátrica",
    "Cirugía Plástica",
    "Cirugía de Cabeza y Cuello",
  ],
};

const AgendarCita = () => {
  const {
    referenciaRefcon,
    setMedicoSeleccionado,
    setReferenciaRefcon,
    isReferenciaHistorica,
  } = useReferenciaRefcon();

  const { metadata } = useLogged();
  const { isTabletOrMobile } = useMediaQueryCustom();
  const [tieneCita, setTieneCita] = useState(false);

  const nav = useNavigate();
  const EspecialidadesPorNombre = useAxios(
    "POST",
    "/api/consulta-externa/buscar/especialidades/nombre",
    false,
    {
      nombre: referenciaRefcon?.especialidad,
    }
  );

  const RevisarDisponibilidad = useAxios(
    "POST",
    "/api/consulta-externa/revisar-citas-por-nro-referencia-v2",
    false,
    {
      referencia: referenciaRefcon?.nroreferencia,
      nombre: referenciaRefcon?.especialidad,
      consultaPorInterconsulta: true,
    }
  );

  const InterconsultasDNI = useAxios(
    "POST",
    "/api/consulta-externa/interconsultas-dni",
    false,
    {
      nroDocumento: metadata?.DataSIGH?.nroDocumento,
    }
  );

  const ListarDisponibilidadCupos = useAxios(
    "POST",
    "/api/consulta-externa/cantidad-citas-programacion/total",
    false,
    {
      nombre: referenciaRefcon?.especialidad,
    }
  );

  useEffect(() => {
    if (!Array.isArray(RevisarDisponibilidad.datos)) {
      setTieneCita(!RevisarDisponibilidad.datos.disponibleParaCita);
      // setRestriccion_caso1(RevisarDisponibilidad.datos.caso === 'CASO_1')
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [RevisarDisponibilidad.datos]);

  useEffect(() => {
    if (!referenciaRefcon) {
      nav("/consulta-externa/generar-cita-sis");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [referenciaRefcon]);

  const getDisponibilidad = (idservicio, idmedico) => {
    const cupos = ListarDisponibilidadCupos.datos.filter(
      (cupo) =>
        parseInt(cupo.IdMedico) === idmedico &&
        parseInt(cupo.IdServicio) === idservicio
    );
    return cupos[0]?.Restantes;
  };

  const quitarTildes = (str) => {
    return str?.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
  };

  return (
    <ContentComponent
      title={
        <Row gap="10px">
          <Button
            type="text"
            onClick={() => {
              setReferenciaRefcon(null);
            }}
          >
            <i className="ri-arrow-left-line"></i>
          </Button>
          Agendar cita para referencia
          <Tag color="blue">{referenciaRefcon?.nroreferencia}</Tag>
        </Row>
      }
    >
      <Column gap="10px" margin="10px 0">
        <Typography variant="h3">
          Seleccione un medico de la lista para agendar su cita
        </Typography>
        <Typography>
          Paciente referido para cita de <b>{referenciaRefcon?.especialidad}</b>
        </Typography>

        {(EspecialidadesPorNombre.cargando ||
          RevisarDisponibilidad.cargando) && (
          <Row>
            <Spin />
            <Typography>Cargando especialidades disponibles...</Typography>
          </Row>
        )}

        {tieneCita && (
          <Heading
            variant="h3"
            color="green"
            style={{
              marginTop: isTabletOrMobile ? "2em" : "0em",
            }}
          >
            Ya tiene una cita agendada para esta referencia.
          </Heading>
        )}
        {!RevisarDisponibilidad.cargando &&
          !tieneCita &&
          EspecialidadesPorNombre.datos
            .filter((el) => {
              const exclude = ExcludeList[referenciaRefcon?.especialidad];
              // remove tildes
              const _serv = quitarTildes(el.Servicio);
              if (exclude) {
                // reduce if the el.Servicio contain on any position of the exclude list
                return !exclude.reduce((acc, curr) => {
                  return acc || _serv.includes(quitarTildes(curr));
                }, false);
              }
              return true;
            })
            .map((medico) => {
              return (
                <CardEspecialidad
                  medico={medico}
                  onClick={() => {
                    setMedicoSeleccionado(medico);
                    const restantes = getDisponibilidad(
                      medico.IdServicio,
                      medico.IdMedico
                    );
                    if (restantes > 0) {
                      nav("/consulta-externa/agendar-cita/seleccionar-fecha");
                    }
                  }}
                  getDisponibilidad={getDisponibilidad}
                />
              );
            })}
        {isReferenciaHistorica && InterconsultasDNI.datos.length > 0 && (
          <Typography variant="h3">Interconsultas disponibles</Typography>
        )}
        {referenciaRefcon &&
          Array.isArray(InterconsultasDNI.datos) &&
          InterconsultasDNI.datos.length > 0 && (
            <Collapse
              bordered={false}
              style={{ background: "transparent" }}
              expandIcon={({ isActive }) => (
                <i
                  class={`ri-arrow-drop-${isActive ? "down" : "right"}-line`}
                />
              )}
            >
              {isReferenciaHistorica &&
                InterconsultasDNI.datos
                  // .filter(filtroInterconsultas)
                  .map((interconsulta, idx) => {
                    return (
                      <Collapse.Panel
                        key={idx}
                        header={
                          <Heading
                            variant="h4"
                            style={{ padding: 0, margin: 0 }}
                          >
                            {interconsulta?.Nombre}
                          </Heading>
                        }
                        style={{
                          marginBottom: 5,
                          background: "#F2F2FE",
                          borderRadius: 8,
                          border: "none",
                        }}
                      >
                        <Interconsultas
                          interconsulta={interconsulta}
                          getDisponibilidad={getDisponibilidad}
                        />
                      </Collapse.Panel>
                    );
                  })}
            </Collapse>
          )}
      </Column>
    </ContentComponent>
  );
};

export default AgendarCita;
