import { Avatar, Dropdown } from "antd";
import { useLogged } from "core/hooks/LoggedContext";
// import { useUsuario } from "core/hooks/UNUSED_UsuarioContext";
import { Column, Row, Typography } from "core/util-styled-components";
import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Theme } from "theme/Theme";

const itemsLogged = ({ nav, events }) => [
  {
    key: "1",
    label: (
      <Typography
        onClick={() => {
          nav("/");
        }}
      >
        Go to Home
      </Typography>
    ),
  },
  {
    key: "2",
    label: (
      <Typography
        onClick={() => {
          events.cerrarSesion();
        }}
      >
        Cerrár sesión
      </Typography>
    ),
  },
];

const Usuario = () => {
  const { isLogged } = useLogged();

  const { metadata, logout } = useLogged();
  const nav = useNavigate();

  useEffect(() => {
    if (!isLogged) {
      nav("/Login");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged]);

  return (
    <Dropdown
      menu={{
        items: isLogged
          ? itemsLogged({
              nav,
              events: {
                cerrarSesion: () => {
                  logout();
                },
              },
            })
          : [],
      }}
    >
      <Row gap=".5em">
        <Avatar
          icon={<i className="ri-user-line" />}
          style={{
            backgroundColor: metadata?.isWorker
              ? Theme.colors.warning
              : Theme.colors.info,
          }}
        />
        <Column alignItems="flex-start">
          <Typography style={{ textTransform: "uppercase" }}>
            <b>{metadata?.DataSIGH?.nombres}</b>
          </Typography>
          <Typography variant="label">
            <strong>Hist. Clinica:</strong>{" "}
            {metadata?.DataSIGH?.NroHistoriaClinica || "No disponible"}
          </Typography>
        </Column>
      </Row>
    </Dropdown>
  );
};

export default Usuario;
