import { Button, message, Modal } from "antd";
import { useLogged } from "core/hooks/LoggedContext";
import useAxios from "core/hooks/useAxios";
import useLocalStorage from "core/hooks/useLocalStorage";
import { Formulario } from "core/ts_formulario_model";
import { Row, Typography } from "core/util-styled-components";
import React, { useState, useEffect } from "react";

const RevisarTelefono = () => {
  const { metadata } = useLogged();
  const [modalUpdateTelefono, setModalUpdateTelefono] = useState(false);
  const [setIsInstanceOfLogged, isInstanceOfLogged] = useLocalStorage(
    "IS_INSTANCE_OF_LOGIN"
  );

  const APIActualizarTelefono = useAxios(
    "POST",
    "/api/actualizar-telefono-sigh",
    true
  );

  const [activeFormUpdateTelefono, setActiveFormUpdateTelefono] =
    useState(false);

  useEffect(() => {
    if (APIActualizarTelefono.respuestaServer === 200) {
      message.success("Telefono actualizado correctamente");
      setIsInstanceOfLogged(false);
      setModalUpdateTelefono(false);
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [APIActualizarTelefono.respuestaServer]);

  useEffect(() => {
    if (!metadata?.DataSIGH?.Telefono) {
      setActiveFormUpdateTelefono(true);
    }
    return () => {};
  }, [metadata]);

  useEffect(() => {
    setModalUpdateTelefono(isInstanceOfLogged());
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Modal
      title="Actualizar numero de telefono"
      visible={modalUpdateTelefono}
      closable={false}
      onCancel={null}
      footer={
        !activeFormUpdateTelefono && (
          <Row justifyContent="center" responsiveReorder>
            <Button
              onClick={() => {
                setActiveFormUpdateTelefono(true);
              }}
            >
              No es mi numero de telefono
            </Button>
            <Button
              type="primary"
              onClick={() => {
                setIsInstanceOfLogged(false);
                setModalUpdateTelefono(false);
              }}
            >
              Si es mi numero de telefono
            </Button>
          </Row>
        )
      }
    >
      {!activeFormUpdateTelefono && (
        <Typography variant="h3">
          Hola, {metadata?.DataSIGH?.nombres}, Confirme que{" "}
          <strong>{metadata?.DataSIGH?.Telefono}</strong> es su numero de
          telefono actual
        </Typography>
      )}

      {activeFormUpdateTelefono && (
        <Formulario
          onSubmit={({ telefono }) => {
            const params = {
              telefono,
              nroDocumento: metadata?.DataSIGH?.nroDocumento,
            };
            APIActualizarTelefono.actualizarParametros(params);
            APIActualizarTelefono.iniciarPeticion();
          }}
        >
          <Formulario.FormControls
            cols={1}
            inputs={[
              {
                label: "Ingrese su numero de telefono",
                name: "telefono",
                type: "input",
                required: true,
                customProps: {
                  required: true,
                  type: "number",
                },
              },
            ]}
            submitLabel="Actualizar telefono"
            buttonProps={{
              submit: {
                color: "primary",
                style: {
                  width: "100%",
                },
              },
              cancel: {
                color: "secondary",
                style: {
                  display: "none",
                },
              },
            }}
          />
        </Formulario>
      )}
    </Modal>
  );
};

export default RevisarTelefono;
