import { Button, Card } from "antd";
import { useContext } from "core/hooks/Context";
import { useMediaQueryCustom } from "core/hooks/useMediaQueryCustom";
import { Row, Heading, Grid, Typography } from "core/util-styled-components";
import { GridChild } from "core/util-styled-components/components/Grid";
import { HomeMenu } from "data";
import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Theme } from "theme/Theme";

const LateralBar = () => {
  const { barActive, setBarActive } = useContext();

  const { isTabletOrMobile } = useMediaQueryCustom();

  const nav = useNavigate();
  const loc = useLocation();
  return (
    <Card
      style={{
        width: isTabletOrMobile ? (barActive ? "100%" : "0%") : "350px",
        maxWidth: isTabletOrMobile ? (barActive ? "100%" : "0%") : "350px",
        minWidth: isTabletOrMobile ? (barActive ? "100%" : "0%") : "350px",
        height: "calc(100vh - 6.5em)",
        overflow: "auto",

        transition: "all .3s ease",
      }}
      bodyStyle={{ padding: "0 1em" }}
    >
      <Grid cols={1} gap="1em">
        {HomeMenu.map((item, index) => {
          if (item.header) {
            return (
              <GridChild span={1}>
                <Heading
                  variant="h3"
                  color={Theme.colors.complementary + "96"}
                  key={index}
                >
                  {item.header}
                </Heading>
              </GridChild>
            );
          }
          return (
            <Row
              key={index}
              justifyContent="space-between"
              alignItems="center"
              onClick={() => {
                setBarActive(false);
                nav(item.path);
              }}
              style={{
                cursor: "pointer",
              }}
            >
              <Row gap=".6em">
                <i
                  className={`ri-${item.icon}`}
                  style={{ fontSize: "28px", color: Theme.colors.primary }}
                />
                <Typography>{item.title}</Typography>
              </Row>
              <Button
                shape="circle"
                type="link"
                size="small"
                disabled={loc.pathname !== item.path}
              >
                <i className="ri-arrow-right-s-line" />
              </Button>
            </Row>
          );
        })}
      </Grid>
    </Card>
  );
};

export default LateralBar;
