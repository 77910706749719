import { Card } from "antd";
import styled from "styled-components";

export const Toolbar = styled.div`
  width: 100%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0.8em 5%;
  box-shadow: 0 0 0.5em 0.25em ${({ theme }) => theme.colors.primary}11;
  z-index: 100;
  box-sizing: border-box;
  height: 4.5em;
`;

export const ContainerGlobal = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  min-height: 100vh;
  background-color: ${({ theme }) => theme.colors.background};
  // polka dots
  background-image: radial-gradient(
      ${({ theme }) => theme.colors.primary}11 1px,
      transparent 1px
    ),
    radial-gradient(
      ${({ theme }) => theme.colors.primary}11 1px,
      transparent 1px
    );
  background-position: 0 0, 50px 50px;
  background-size: 10px 10px;
  * {
    box-sizing: border-box;
  }
`;

export const Content = styled(Card)`
  width: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  border-radius: 0.25em;
  margin: 0 auto;
  height: calc(100vh - 6.5em);
  overflow: hidden;
  position: relative;
`;

export const ContentLayout = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  box-sizing: border-box;
  margin: 0 auto;
  border-radius: 0.25em;
  height: calc(100vh - 4.5em);
  overflow: hidden;
  padding: 0.5em 1%;
  gap: 1em;
`;
