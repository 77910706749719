const Shader = {
  "TERAPIA FÍSICA Y REHABILITACIÓN": "Medicina Física y Rehab",
  "MEDICINA INTERNA": "Medicina Interna",
  "CIRUGÍA PLÁSTICA": "Cirugía Plástica",
  "CIRUGÍA DE CABEZA Y CUELLO": "Cirugía de Cabeza y Cuello",
  "CIRUGÍA PEDIÁTRICA": "Cirugía Pediátrica",
};

export const parseEspecialidadNombre = (especialidad) => {
  const esp = Shader[especialidad];

  if (esp) {
    return esp;
  }

  return especialidad?.split(" ")[0];
};
