import React, { useMemo, useState } from "react";

const Context = React.createContext();
export function Provider(props) {
  const [barActive, setBarActive] = useState(false);

  const value = useMemo(() => {
    return {
      barActive,
      setBarActive,
    };
    //eslint-disable-next-line
  }, [barActive]);

  return <Context.Provider value={value} {...props} />;
}
export function useContext() {
  const context = React.useContext(Context);
  if (!context) {
    throw new Error("No se encontro Usuario en el contexto");
  }
  return context;
}
