import React, { useEffect, useState } from "react";

const Timer = ({ onTimerComplete }) => {
  const [secondsRemaining, setSecondsRemaining] = useState(2 * 60); // Inicializa el contador en 3 minutos (3 * 60 segundos)

  useEffect(() => {
    const intervalId = setInterval(() => {
      setSecondsRemaining((prevSeconds) => prevSeconds - 1);
    }, 1000);

    // La función de limpieza de useEffect para limpiar el intervalo cuando el componente se desmonta o el contador llega a cero
    return () => clearInterval(intervalId);
  }, []);

  useEffect(() => {
    // Si el contador llega a cero, se ejecuta la función `onTimerComplete`
    if (secondsRemaining === 0) {
      onTimerComplete();
    }
  }, [secondsRemaining, onTimerComplete]);

  // Calcula los minutos y segundos restantes
  const minutes = Math.floor(secondsRemaining / 60);
  const seconds = secondsRemaining % 60;

  // Formatea el tiempo restante en un formato de minutos:segundos
  const formattedTime = `${minutes.toString().padStart(2, "0")}:${seconds
    .toString()
    .padStart(2, "0")}`;

  return formattedTime;
};

export default Timer;
