import { Button, Card, Table, Tag } from "antd";
import ContentComponent from "components/skeleton/ContentComponent";
import useAxios from "core/hooks/useAxios";
import { Column, Heading, Row, Typography } from "core/util-styled-components";
import { generateMapTable } from "core/utils/tables";
import { ProcesarData, ResultadoLaboratorioPDF } from "exports/Laboratorio";
import React, { useState } from "react";
import { useParams } from "react-router-dom";

const DetalleEstudio = () => {
  const { id } = useParams();

  const [dataProcesada, setDataProcesada] = useState([]);

  const DetallesAPI = useAxios(
    "POST",
    "/api/laboratorio/hospitalizacion/detalles/listar",
    true
  );

  const VerResultadosDetallado = useAxios(
    "POST",
    "/api/laboratorio/hospitalizacion/detalles/detallar",
    true
  );

  React.useEffect(() => {
    DetallesAPI.actualizarParametros({
      identificador: id,
    });
    DetallesAPI.iniciarPeticion();

    VerResultadosDetallado.actualizarParametros({
      identificador: id,
      ctpCodigo: "12346",
    });
    VerResultadosDetallado.iniciarPeticion();

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id]);

  React.useEffect(() => {
    if (!(Array.isArray(DetallesAPI.datos) && DetallesAPI.datos.length > 0))
      return;
    if (
      !(
        Array.isArray(VerResultadosDetallado.datos) &&
        VerResultadosDetallado.datos.length > 0
      )
    )
      return;
    procesarResultados(DetallesAPI.datos, VerResultadosDetallado.datos);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [DetallesAPI.datos, VerResultadosDetallado.datos]);

  const procesarResultados = (datos, results) => {
    if (Array.isArray(datos) && datos.length === 0) return;
    const cpt = datos?.map((item) => {
      return {
        ...item,
        resultados: results?.filter((r) => r.cptCodigo === item.cptCodigo),
      };
    });

    setDataProcesada(cpt);
  };

  const DownloadFile = () => {
    ResultadoLaboratorioPDF({
      data: dataProcesada,
      identificador: id,
      cabSeleccionado: {},
    });
  };

  return (
    <ContentComponent
      title={
        <Heading variant="h2" style={{ marginBottom: ".5em" }}>
          Detalle Estudio
        </Heading>
      }
      extra={
        <Button type="primary" onClick={DownloadFile}>
          <Typography color="inherit">Imprimir</Typography>
        </Button>
      }
    >
      <Column gap="10px">
        {dataProcesada?.map((item) => {
          return (
            <Card>
              <Row
                justifyContent="space-between"
                style={{ marginBottom: ".5em" }}
              >
                <Typography>
                  <strong>{item.cpt}</strong>
                </Typography>
                <Typography>
                  Resultado registrado:{" "}
                  {item.FechaHoraResultado ? (
                    <Tag color="cyan">
                      <Typography color="inherit">
                        {item.FechaHoraResultado.split("T")[0]}
                      </Typography>
                    </Tag>
                  ) : (
                    <Tag color="red">
                      <Typography color="inherit">Sin Resultado</Typography>
                    </Tag>
                  )}
                </Typography>
              </Row>
              <Table
                dataSource={item.resultados}
                bordered
                style={{
                  overflow: "auto",
                }}
                columns={generateMapTable([
                  "item",
                  "Grupo",
                  {
                    title: "Resultado",
                    dataIndex: "itemResultado",
                  },
                  {
                    title: "Und",
                    dataIndex: "und",
                  },
                  {
                    title: "Referencia",
                    dataIndex: "itemReferencia",
                  },
                  {
                    title: "Metodo",
                    dataIndex: "itemMetodo",
                  },
                ])}
              />
            </Card>
          );
        })}
        {dataProcesada?.length === 0 && (
          <Typography variant="h4" align="center">
            No se encontraron resultados
          </Typography>
        )}
      </Column>
    </ContentComponent>
  );
};

export default DetalleEstudio;
