import {
  parse,
  differenceInYears,
  differenceInMonths,
  differenceInDays,
  addMonths,
  getYear,
  isLeapYear,
  format,
  addHours,
  addMinutes,
} from "date-fns";

const getTimeZone = (date?: Date) => {
  const DateForCalculate: Date = date ? date : new Date();
  return -1 * (DateForCalculate.getTimezoneOffset() / 60);
};

const getLastBirthday = (birthDate: string) => {
  const lastYear = `${birthDate.substring(0, 6)}${getYear(new Date()) - 1}`;
  return parse(lastYear, "dd-MM-yyyy", new Date());
};

const getAge = (birthDate: string) => {
  try {
    let lastBirthDate = getLastBirthday(birthDate);
    const date = parse(birthDate, "dd-MM-yyyy", new Date());
    const ageYears = differenceInYears(new Date(), date);
    let ageMonths = differenceInMonths(new Date(), lastBirthDate);
    lastBirthDate = addMonths(lastBirthDate, ageMonths);
    let ageDays = differenceInDays(new Date(), lastBirthDate);
    ageMonths = ageMonths === 12 ? 0 : ageMonths;
    const isLeap = isLeapYear(new Date());
    if (isLeap) {
      ageDays === 366 && (ageDays = 0);
    } else {
      ageDays === 365 && (ageDays = 0);
    }
    return {
      birthDate,
      ageYears,
      ageMonths,
      ageDays,
      fullAgeEn: `${ageYears} years, ${ageMonths} months, ${ageDays} days `,
      fullAgeEs: `${ageYears} años, ${ageMonths} meses, ${ageDays} dias`,
    };
  } catch (error) {
    throw new Error("error");
  }
};

const getAPIFormat = (date?: Date) => {
  const DateActive: Date = date ? date : new Date();
  return format(DateActive, "yyyy-MM-dd");
};

const getThisYear = () => format(new Date(), "yyyy");

const formatLocalTZ = (
  dateOnTZ0: string | Date,
  frmt: string = "dd/MM/yyyy hh:mm:ss a"
) => format(addHours(new Date(dateOnTZ0), -getTimeZone()), frmt);

// Calculate diference hours
const getDiferenceHours = (date1: Date, date2: Date) => {
  const diff = Math.abs(date1.getTime() - date2.getTime());
  return Math.ceil(diff / (1000 * 60 * 60));
};

// Calculate diference hours string
const getDiferenceHoursString = (date1: Date, date2: Date) => {
  const diff = Math.abs(date1.getTime() - date2.getTime());
  const hours = Math.floor(diff / (1000 * 60 * 60));
  const minutes = Math.floor((diff / (1000 * 60)) % 60);
  return `${hours < 10 ? "0" + hours : hours}:${
    minutes < 10 ? "0" + minutes : minutes
  }`;
};

const getDiferenceHoursParamsString = (hour1: string, hour2: string) => {
  const isOtherDay1 = isOtherDay(hour1, hour2);
  const date1 = new Date(`01/21/1970 ${hour1}`);
  const date2 = new Date(`01/${isOtherDay1 ? "21" : "22"}/1970 ${hour2}`);
  const diff = getDiferenceHoursString(date1, date2);
  return diff;
};

const isOtherDay = (hour1: string, hour2: string) => {
  const _hour1 = hour1.split(":");
  const _hour2 = hour2.split(":");
  const hour1hour = parseInt(_hour1[0]);
  const hour2hour = parseInt(_hour2[0]);
  const hour1Minutes = parseInt(_hour1[1]);
  const hour2Minutes = parseInt(_hour2[1]);

  if (hour2hour > hour1hour) {
    return true;
  }

  if (hour2hour === hour1hour) {
    if (hour2Minutes >= hour1Minutes) {
      return true;
    }
  }

  return false;
};

const sumarTiempo = (hora: string, minutos: number) => {
  const _hora = hora.split(":");
  const hour = parseInt(_hora[0]);
  const minutes = parseInt(_hora[1]);
  const date = new Date(`01/21/1970 ${hour}:${minutes}`);
  const dateSum = addMinutes(date, minutos);
  return format(dateSum, "HH:mm");
};

const time = {
  getTimeZone,
  getLastBirthday,
  getAge,
  getAPIFormat,
  getThisYear,
  formatLocalTZ,
  getDiferenceHours,
  getDiferenceHoursString,
  getDiferenceHoursParamsString,
  sumarTiempo,
};

export default time;
