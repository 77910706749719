import { Button } from "antd";
import { Heading } from "core/util-styled-components";
import { getFormattedDate } from "pages/ConsultaExterna/utils/ce_util";
import React, { useEffect, useState } from "react";
import { Theme } from "theme/Theme";

const DiaCupo = ({ item, value, isBloqued, isCollapse, onChange }) => {
  const [fecha, setFecha] = useState("");

  useEffect(() => {
    setFecha(getFormattedDate(item.Fecha));

    return () => {};
    //eslint-disable-next-line
  }, [item.Fecha]);

  return (
    <Button
      onClick={() => {
        if (isBloqued || isCollapse) {
          return false;
        }
        onChange();
      }}
      style={{
        color: isCollapse
          ? "#FF4545"
          : isBloqued
          ? "#c3c3c3"
          : Theme.colors.successDark,
        borderColor: isCollapse
          ? "#FF454511"
          : (isBloqued ? "#c3c3c3" : Theme.colors.successDark) +
            (value?.IdProgramacion === item.IdProgramacion ? "FF" : "13"),
        backgroundColor: isCollapse
          ? "#FF454513"
          : (isBloqued ? "#c3c3c3" : Theme.colors.successDark) + "13",
      }}
    >
      <Heading color="inherit" style={{ margin: 0 }}>
        {fecha} {isCollapse && " - Lleno"}
      </Heading>
    </Button>
  );
};

export default DiaCupo;
