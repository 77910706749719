import { Button, Card, Tag } from "antd";
import { Content } from "components/skeleton/styled";
import { useLogged } from "core/hooks/LoggedContext";
import useAxios from "core/hooks/useAxios";
// import { useUsuario } from "core/hooks/UNUSED_UsuarioContext";
import { Column, Heading, Row, Typography } from "core/util-styled-components";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const LaboratorioClinico = () => {
  const { metadata } = useLogged();
  const nav = useNavigate();

  const CabecerasAPI = useAxios(
    "POST",
    "/api/laboratorio/cabeceras/listar",
    true
  );

  useEffect(() => {
    if (metadata?.DataSIGH) {
      CabecerasAPI.actualizarParametros({
        hc: metadata?.DataSIGH?.NroHistoriaClinica,
      });
      CabecerasAPI.iniciarPeticion();
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metadata]);

  return (
    <Content
      title={
        <Heading variant="h3" style={{ top: 0, position: "sticky" }}>
          Laboratorio Clinico
        </Heading>
      }
      bodyStyle={{
        overflow: "auto",
      }}
    >
      {CabecerasAPI.datos?.length === 0 && (
        <Card>
          <Typography>
            No se encontraron resultados de laboratorio clinico
          </Typography>
        </Card>
      )}
      <Column gap="1em">
        {CabecerasAPI.datos
          ?.sort((a, b) => a.identificador - b.identificador)
          .map((cabecera) => (
            <Card
              title={"Orden Nº" + cabecera.num_orden}
              extra={
                <Tag
                  color={
                    cabecera.Descripcion === "Emergencia"
                      ? "cyan-inverse"
                      : "blue-inverse"
                  }
                >
                  {cabecera.Descripcion}
                </Tag>
              }
              style={{
                cursor: "pointer",
              }}
              onClick={() => {
                console.log(cabecera);
                nav({
                  pathname: `/mis-resultados/laboratorio-clinico/${cabecera.identificador}`,
                  state: {
                    cabecera,
                  },
                });
              }}
            >
              <Row justifyContent="space-between">
                <Column alignItems="flex-start">
                  <Typography>
                    <strong>Medico: </strong> {cabecera.medico}
                  </Typography>
                  <Typography>
                    <strong>Fecha: </strong>{" "}
                    {cabecera.fechaHoraMuestra?.split("T")[0]}
                  </Typography>
                  <Typography>
                    <strong>Servicio: </strong> {cabecera.servicio}
                  </Typography>
                </Column>
                <Button>
                  <i
                    className={`ri-arrow-right-s-line`}
                    style={{ fontSize: "15px", color: "#c3c3c3" }}
                  />
                </Button>
              </Row>
            </Card>
          ))}
      </Column>
    </Content>
  );
};

export default LaboratorioClinico;
