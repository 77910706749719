import { useReferenciaRefcon } from 'core/hooks/ReferenciaContext'
import useAxios from 'core/hooks/useAxios'
import { Column, Heading, Row, Typography } from 'core/util-styled-components'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import CardEspecialidad from '../molecules/CardEspecialidad'
import { useMediaQueryCustom } from 'core/hooks/useMediaQueryCustom'
import { Spin } from 'antd'

const Interconsultas = ({ interconsulta }) => {
  const { isTabletOrMobile } = useMediaQueryCustom()

  const [tieneCita, setTieneCita] = useState(false)
  const { referenciaRefcon } = useReferenciaRefcon()
  const EspecialidadesPorNombre = useAxios(
    'POST',
    '/api/consulta-externa/buscar/especialidades/nombre',
    false,
    {
      nombre: interconsulta?.Nombre?.replaceAll('Interconsulta de', '')
        ?.replaceAll('Interconsulta', '')
        ?.trim()
    }
  )

  const RevisarDisponibilidad = useAxios(
    'POST',
    '/api/consulta-externa/revisar-citas-por-nro-referencia-v2',
    false,
    {
      referencia: referenciaRefcon.nroreferencia,
      nombre: interconsulta?.Nombre?.replaceAll('Interconsulta de', '')
        ?.replaceAll('Interconsulta', '')
        ?.trim(),
      consultaPorInterconsulta: true
    }
  )

  useEffect(() => {
    if (referenciaRefcon.nroreferencia) {
      RevisarDisponibilidad.actualizarParametros({
        referencia: referenciaRefcon.nroreferencia,
        nombre: interconsulta?.Nombre?.replaceAll('Interconsulta de', '')
          ?.replaceAll('Interconsulta', '')
          ?.trim(),
        consultaPorInterconsulta: true
      })
      RevisarDisponibilidad.iniciarPeticion()
    }
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [interconsulta, referenciaRefcon])

  useEffect(() => {
    if (!Array.isArray(RevisarDisponibilidad.datos)) {
      setTieneCita(!RevisarDisponibilidad.datos.disponibleParaCita)
      // setRestriccion_caso1(RevisarDisponibilidad.datos.caso === 'CASO_1')
    }
    return () => {}
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [RevisarDisponibilidad.datos])

  const ListarDisponibilidadCupos = useAxios(
    'POST',
    '/api/consulta-externa/cantidad-citas-programacion/total',
    false,
    {
      nombre: interconsulta?.Nombre?.replaceAll('Interconsulta de', '')
        ?.replaceAll('Interconsulta', '')
        ?.trim()
    }
  )
  const { setMedicoSeleccionado } = useReferenciaRefcon()

  const nav = useNavigate()
  const getDisponibilidad = (idservicio, idmedico) => {
    const cupos = ListarDisponibilidadCupos.datos.filter(
      cupo =>
        parseInt(cupo.IdMedico) === idmedico &&
        parseInt(cupo.IdServicio) === idservicio
    )
    return cupos[0]?.Restantes
  }
  return (
    <Column gap='10px'>
      {/* <Typography>{interconsulta.Nombre}</Typography> */}
      {
        // si tiene cita, no mostrar el botón
        !RevisarDisponibilidad.cargando && tieneCita && (
          <Heading
            variant='h3'
            color='green'
            style={{
              marginTop: isTabletOrMobile ? '2em' : '0em'
            }}
          >
            Ya tiene una cita agendada para esta referencia.
          </Heading>
        )
      }
      {RevisarDisponibilidad.cargando && (
        <Row gap='10px'>
          <Spin />
          <Typography>
            Revisando disponibilidad de citas para esta referencia...
          </Typography>
        </Row>
      )}

      {!RevisarDisponibilidad.cargando &&
        !tieneCita &&
        EspecialidadesPorNombre.datos.map(medico => {
          return (
            <CardEspecialidad
              medico={medico}
              getDisponibilidad={getDisponibilidad}
              onClick={() => {
                setMedicoSeleccionado(medico)
                const restantes = getDisponibilidad(
                  medico.IdServicio,
                  medico.IdMedico
                )
                if (restantes > 0) {
                  nav('/consulta-externa/agendar-cita/seleccionar-fecha')
                }
              }}
            />
          )
        })}
    </Column>
  )
}

export default Interconsultas
