import { Formulario } from "core/ts_formulario_model";
import {
  Column,
  Div,
  Heading,
  Row,
  Typography,
} from "core/util-styled-components";
import { generateMapForm } from "core/utils/tables";
import React, { useEffect, useState } from "react";
import ImgLeyenda from "assets/DNI_INDICACIONES.jpg";
import { useNavigate } from "react-router-dom";
import { useMediaQueryCustom } from "core/hooks/useMediaQueryCustom";
import { Theme } from "theme/Theme";
import { ContentLayout } from "components/skeleton/styled";
import Logo from "components/atoms/Logo";
import useAxios from "core/hooks/useAxios";
import { Button, message, Modal } from "antd";
import { useLogged } from "core/hooks/LoggedContext";
import useLocalStorage from "core/hooks/useLocalStorage";

const Notas = () => (
  <Column>
    <Typography>
      <strong>Notas:</strong>
    </Typography>
    <ul>
      <li>
        <Typography>
          Si usted no cuenta con una historia clinica en el hospital, debe
          acercarse al establecimiento de salud para crearla.
        </Typography>
      </li>
      <li>
        <Typography>
          Si no tiene DNI o es Extranjero, debe acercarse al establecimiento de
          salud para poder realizar cualquier operación.
        </Typography>
      </li>
      <li>
        <Typography>
          Para el proceso de generación de cita web, el servicio solo se
          encuentra disponible para pacientes que cuenten con SIS.
        </Typography>
      </li>
    </ul>
  </Column>
);

const LoginReniec = () => {
  const nav = useNavigate();
  const { login, isLogged } = useLogged();
  const { isTabletOrMobile } = useMediaQueryCustom();
  const [setIsInstanceOfLogged] = useLocalStorage("IS_INSTANCE_OF_LOGIN");
  const [verIndicaciones, setVerIndicaciones] = useState(false);
  const [leerNotas, setLeerNotas] = useState(false);

  const LoginPaciente = useAxios("POST", "/api/login-paciente-2", true);
  const onSubmit = (data) => {
    LoginPaciente.actualizarParametros({
      DNI: data.DNI.trim(),
      fechaEmision: data.fechaEmision.trim().replaceAll("-", ""),
    });
    LoginPaciente.iniciarPeticion();
  };

  useEffect(() => {
    setIsInstanceOfLogged(true);
    return () => {};
  }, []);

  useEffect(() => {
    if (!Array.isArray(LoginPaciente.datos) && LoginPaciente.datos) {
      const resp = LoginPaciente.datos;
      resp.error && message.error(resp.message);

      if (!resp.error) {
        message.success("Bienvenido al Hospital de la Amistad");

        login(resp);
      }
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [LoginPaciente.datos]);

  useEffect(() => {
    if (isLogged) {
      nav("/");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged]);

  return (
    <ContentLayout>
      <Row
        width="100%"
        style={{
          flexDirection: isTabletOrMobile ? "column-reverse" : "row",
          padding: isTabletOrMobile ? "0" : "1em",
          overflow: "auto",
        }}
      >
        <Formulario
          style={{
            width: isTabletOrMobile ? "100%" : "50%",
            padding: isTabletOrMobile ? "0 1.2em" : "1em 3em",
            backgroundColor: Theme.colors.white,
            borderRadius: 10,
            boxShadow: isTabletOrMobile
              ? "none"
              : "0px 0px 2px 0px rgba(0,0,0,0.1)",
          }}
          onSubmit={onSubmit}
        >
          <Formulario.Title centered style={{ marginBottom: 30 }}>
            <Column gap="10px">
              <Logo />
              <Heading variant="h3">Plataforma de consulta externa</Heading>
              <Typography style={{ fontWeight: "300" }}>
                Para poder acceder a la plataforma, por favor ingrese los
                siguientes{" "}
                {isTabletOrMobile ? (
                  <span
                    onClick={() => {
                      setVerIndicaciones(true);
                    }}
                  >
                    datos
                  </span>
                ) : (
                  "datos"
                )}
                :{" "}
                {isTabletOrMobile && (
                  <Button type="dashed">
                    <Typography
                      color="inherit"
                      onClick={() => {
                        setVerIndicaciones(true);
                      }}
                    >
                      ¿Donde están mis datos?
                    </Typography>
                  </Button>
                )}
              </Typography>
            </Column>
          </Formulario.Title>

          <Formulario.FormControls
            onCancel={() => nav("/Login")}
            cols={1}
            submitLabel="Validar datos"
            inputs={generateMapForm([
              {
                name: "DNI",
                type: "input",
                required: true,
                customProps: {
                  placeholder: "Ingrese su dni",
                  required: true,
                  type: "number",
                },
              },
              {
                name: "fechaEmision",
                type: "input",
                required: true,
                customProps: {
                  placeholder: "Ingrese su fecha de emisión",
                  required: true,
                  type: "date",
                },
              },
            ])}
            buttonProps={{
              justify: "space-between",
              submit: {
                color: "primary",
                style: {
                  width: "100%",
                },
              },
              cancel: {
                color: "secondary",
                style: {
                  width: "100%",
                  display: "none",
                },
              },
            }}
          >
            {isTabletOrMobile && (
              <Button
                type="link"
                onClick={() => {
                  setLeerNotas(true);
                }}
              >
                <Typography color="inherit">Leer notas del EESS</Typography>
              </Button>
            )}
            {!isTabletOrMobile && <Notas />}
          </Formulario.FormControls>
        </Formulario>
        {isTabletOrMobile && (
          <Modal
            visible={leerNotas}
            onCancel={() => {
              setLeerNotas(false);
            }}
            footer={
              <Button
                type="primary"
                onClick={() => {
                  setLeerNotas(false);
                }}
              >
                Aceptar
              </Button>
            }
          >
            <Notas />
          </Modal>
        )}

        {isTabletOrMobile && (
          <Modal
            visible={verIndicaciones}
            onCancel={() => {
              setVerIndicaciones(false);
            }}
            footer={
              <Button
                type="primary"
                onClick={() => {
                  setVerIndicaciones(false);
                }}
              >
                Aceptar
              </Button>
            }
          >
            <img
              src={ImgLeyenda}
              alt="..."
              style={{
                width: "100%",
                maxHeight: "100%",
                borderLeft: "1px solid #F2F2F2",
              }}
            />
          </Modal>
        )}
        {!isTabletOrMobile && (
          <Div width="40%" margin="1em" style={{ maxHeight: "100vh" }}>
            <img
              src={ImgLeyenda}
              alt="..."
              style={{
                width: "100%",
                maxHeight: "100%",
                borderLeft: "1px solid #F2F2F2",
              }}
            />
          </Div>
        )}
      </Row>
    </ContentLayout>
  );
};

export default LoginReniec;
