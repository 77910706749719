import Axios from "axios";
import { HOST_API, PARAMS_HOST } from "core/host";
import { axiosNotification } from "core/utils/notificaciones";
import { saveAs } from "file-saver";
//import { baseUrl } from "../static/constantes";
const baseUrl = HOST_API;
export interface DXProps {
  informe: string;
  idusuario: number;
  md: string;
  paciente: string;
}

const msgs = {
  cargando: "Descargando archivo...",
  correcto: "Descarga exitosa",
  error: "Error en la descarga",
};
export const InformeDXPDF = (props: DXProps) => {
  axiosNotification(true, 0, msgs);
  Axios.post(`${baseUrl}/api/create-pdf`, {
    data: props.informe,
    id: props.idusuario,
  })
    .then(() => {
      return Axios.get(`${baseUrl}/api/fetch-pdf`, {
        responseType: "blob",
      });
    })
    .then((res) => {
      Axios.get(`${baseUrl}/api/remove-pdf`);
      axiosNotification(false, 200, msgs);
      const pdfBlob = new Blob([res.data], { type: "application/pdf" });
      saveAs(pdfBlob, `${new Date().getTime()} - DX Imagen.pdf`);
    })
    .catch((err) => {
      axiosNotification(false, 400, msgs);
    });
};
