import { Button, Card } from "antd";
import { useMediaQueryCustom } from "core/hooks/useMediaQueryCustom";
import { Heading, Row, Typography } from "core/util-styled-components";
import React from "react";
import { useNavigate } from "react-router-dom";

const CardSO = () => {
  const { isTabletOrMobile } = useMediaQueryCustom();
  const nav = useNavigate();
  const onClick = () => {
    nav("/consulta-externa/agendar-cita-so");
  };

  return (
    <Card>
      <Row justifyContent="space-between">
        <Typography>Citas de salud ocupacional</Typography>
        <Button
          type="primary"
          size="large"
          style={{
            // marginTop: isTabletOrMobile ? "2em" : "-2em",
            width: isTabletOrMobile ? "100%" : "auto",
          }}
          onClick={onClick}
        >
          <Heading
            variant="h3"
            style={{
              margin: 0,
              padding: 0,
            }}
          >
            Agendar cita
          </Heading>
        </Button>
      </Row>
    </Card>
  );
};

export default CardSO;
