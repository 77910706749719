import useAxios from "core/hooks/useAxios";
import React from "react";
import CardEspecialidad from "./components/molecules/CardEspecialidad";
import ContentComponent from "components/skeleton/ContentComponent";
import { Column, Typography } from "core/util-styled-components";
import { useReferenciaRefcon } from "core/hooks/ReferenciaContext";
import { useNavigate } from "react-router-dom";

const nombre = "Salud Ocupacional";

const AgendarCitaSO = () => {
  const { setMedicoSeleccionado } = useReferenciaRefcon();
  const nav = useNavigate();

  const EspecialidadesPorNombre = useAxios(
    "POST",
    "/api/consulta-externa/buscar/especialidades/nombre",
    false,
    {
      nombre,
    }
  );
  const ListarDisponibilidadCupos = useAxios(
    "POST",
    "/api/consulta-externa/cantidad-citas-programacion/total",
    false,
    {
      nombre,
      useTotalEnProgramacion: true,
    }
  );

  const getDisponibilidad = (idservicio, idmedico) => {
    const cupos = ListarDisponibilidadCupos.datos.filter(
      (cupo) =>
        parseInt(cupo.IdMedico) === idmedico &&
        parseInt(cupo.IdServicio) === idservicio
    );
    return cupos[0]?.TotalEnProgramacion;
  };

  return (
    <ContentComponent title="Agendar cita para Salud Ocupacional">
      <Column gap="10px" margin="10px 0">
        <Typography variant="h3">
          Seleccione un medico de la lista para agendar su cita
        </Typography>
        {EspecialidadesPorNombre.datos.map((medico) => {
          return (
            <CardEspecialidad
              medico={medico}
              onClick={() => {
                setMedicoSeleccionado(medico);
                const restantes = getDisponibilidad(
                  medico.IdServicio,
                  medico.IdMedico
                );
                if (restantes > 0) {
                  nav("/consulta-externa/agendar-cita-so/seleccionar-fecha");
                }
              }}
              getDisponibilidad={getDisponibilidad}
            />
          );
        })}
      </Column>
    </ContentComponent>
  );
};

export default AgendarCitaSO;
