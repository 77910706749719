import { Card } from "antd";
import { Column, Grid, Heading, Typography } from "core/util-styled-components";
import { GridChild } from "core/util-styled-components/components/Grid";
import { HomeMenu } from "data";
import { Theme } from "theme/Theme";
import { useNavigate } from "react-router-dom";
import ContentComponent from "components/skeleton/ContentComponent";

const Home = () => {
  const nav = useNavigate();
  return (
    <ContentComponent>
      <Grid cols={2} gap="1em">
        {HomeMenu.map((item, index) => {
          if (item.header) {
            return (
              <GridChild span={2}>
                <Heading variant="h2" key={index}>
                  {item.header}
                </Heading>
              </GridChild>
            );
          }
          return (
            <Card
              hoverable
              onClick={() => {
                nav(item.path);
              }}
            >
              <Column key={index} justifyContent="center" alignItems="center">
                <i
                  className={`ri-${item.icon}`}
                  style={{ fontSize: "30px", color: Theme.colors.primary }}
                />
                <Typography>{item.title}</Typography>
              </Column>
            </Card>
          );
        })}
      </Grid>
    </ContentComponent>
  );
};

export default Home;
