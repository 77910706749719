import Axios from "axios";
import { HOST_API, PARAMS_HOST } from "core/host";
import { axiosNotification } from "core/utils/notificaciones";
import { saveAs } from "file-saver";
const baseUrl = HOST_API;
export interface DXProps {
  data: any;
  cabSeleccionado: any;
}

const msgs = {
  cargando: "Descargando archivo...",
  correcto: "Descarga exitosa",
  error: "Error en la descarga",
};

export const ResultadoLaboratorioPDF = (data: DXProps) => {
  axiosNotification(true, 0, msgs);

  Axios.post(`${baseUrl}/api/pdf-laboratorio/create-pdf`, {
    data,
  })
    .then(() =>
      Axios.get(`${baseUrl}/api/pdf-laboratorio/fetch-pdf`, {
        responseType: "blob",
      })
    )
    .then((res) => {
      Axios.get(`${baseUrl}/api/pdf-laboratorio/remove-pdf`);
      axiosNotification(false, 200, msgs);
      const pdfBlob = new Blob([res.data], { type: "application/pdf" });
      saveAs(pdfBlob, `Resultados de laboratorio.pdf`);
    })
    .catch((err) => {
      axiosNotification(false, 400, msgs);
    });
};
