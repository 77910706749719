import Pdf from "jspdf";
import Axios from "axios";
import moment from "moment";
import { axiosNotification } from "core/utils/notificaciones";
import { HOST_API, PARAMS_HOST } from "core/host";

const msgs = {
  cargando: "Descargando archivo...",
  correcto: "Descarga exitosa",
  error: "Error en la descarga",
};
const Ticket = async (ncuenta) => {
  try {
    axiosNotification(true, 0, msgs);

    const res = await Axios.post(HOST_API + PARAMS_HOST, {
      method: "GET",
      route: `/api/consultar/ticket/${ncuenta}`,
    });
    console.log(res.data);
    const ticket = res.data[0];
    //nuevo documento de pdf 47416604
    let doc = new Pdf("p", "pt", "a4");

    //CONSTRUCCION DEL PDF
    doc.setFontSize(14);
    doc.setTextColor(30);
    doc.setFontStyle("bold");
    doc.text(22, 30, "HOSPITAL DE LA AMISTAD PERU-COREA");
    doc.text(110, 50, "SANTA ROSA II - 2");
    doc.setFontSize(8);
    doc.setFontStyle("bold");
    doc.text(140, 60, "RUC: 20525832334");
    doc.text(100, 70, "AV. GRAU - CHULUCANA S/N PIURA");
    doc.text(140, 80, "Telf: 073-6000-13");
    doc.setFontSize(12);
    doc.setTextColor(30);
    doc.setFontStyle("normal");
    doc.setFontSize(26);
    doc.setFontStyle("bold");
    doc.text(70, 110, "TICKET DE CITA");
    doc.setFontSize(12);
    doc.setFontStyle("bold");
    doc.text(22, 130, "Fecha :");
    doc.setFontStyle("normal");
    doc.text(65, 130, ticket.FECHA);
    doc.text(22, 150, ticket.TURNO);
    doc.setFontStyle("bold");
    doc.text(22, 170, "Servicio: " + ticket.ESPECIALIDAD);
    doc.text(22, 190, "Medico: ");
    doc.text(22, 210, ticket.MEDICO);
    doc.setFontStyle("normal");
    doc.setTextColor(90);
    doc.text(22, 212, "_____________________________________________");
    doc.setTextColor(30);
    doc.setFontSize(14);
    doc.setFontStyle("bold");
    doc.text(22, 230, "N°Historia: " + ticket.HISTORIA);
    doc.text(22, 250, "N°Cuenta: " + ticket.CUENTA);
    doc.setFontSize(12);
    doc.text(22, 270, "Paciente");
    doc.text(22, 290, ticket.PACIENTE);
    doc.text(22, 310, ticket.FUENTEFINANCIAMIENTO);
    doc.setFontStyle("normal");
    doc.setTextColor(90);
    doc.text(22, 312, "_____________________________________________");
    doc.setFontStyle("bold");
    doc.setTextColor(30);
    doc.text(22, 330, "Fecha :" + moment().format("DD/MM/YYYY hh:mm a"));
    doc.text(90, 350, "Conserve su Ticket, Gracias!");
    doc.text(
      22,
      370,
      "Fecha y hora de impresión : " + moment().format("DD/MM/YYYY hh:mm a")
    );
    //GUARDANDO PDF
    doc.save(`${ncuenta}-${ticket.PACIENTE}.pdf`);
    axiosNotification(false, 200, msgs);
  } catch (error) {
    axiosNotification(false, 400, msgs);
  }
};

export default Ticket;
