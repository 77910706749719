import React, { useState, useEffect, useMemo } from "react";
import useLocalStorage from "./useLocalStorage";
import { Row, Spin } from "antd";
import { fetchPost } from "core/utils/simpleAxios";

const LoggedContext = React.createContext();
export function LoggedProvider(props) {
  const [isLogged, setIsLogged] = useState(false);

  const [metadata, setMetadata] = useState(null);
  const [loading, setLoading] = useState(true);
  const [setLogin, getLogin] = useLocalStorage("@login/logged-paciente");

  useEffect(() => {
    recuperarUsuario();
    return () => {};
    //eslint-disable-next-line
  }, []);
  const recuperarUsuario = async () => {
    setLoading(true);
    const store = getLogin();
    if (store && store.login) {
      const { dni } = store;

      const response = await fetchPost(
        "/api/paciente-get-metadata",
        { DNI: dni },
        store?.store + " 1050"
      );
      const { code } = response.data;
      if (code === 200) {
        setMetadata({
          ...response.data.metadata,
          store: store.store,
        });
        setIsLogged(true);
      } else {
        logout();
      }
    }
    setLoading(false);
  };

  const login = async ({ SSID, DNI }) => {
    try {
      setLogin({
        login: true,
        store: SSID,
        dni: DNI,
      });
      recuperarUsuario();
    } catch (error) {}
  };

  useEffect(() => {
    setIsLogged(!!metadata);

    return () => {};
  }, [metadata]);

  const logout = () => {
    localStorage.removeItem("@login/logged-paciente");
    sessionStorage.clear();
    setMetadata(null);
    setIsLogged(false);
  };

  const value = useMemo(() => {
    return {
      login,
      logout,
      metadata,
      isLogged,
      // validacionREFCON,
    };
    //eslint-disable-next-line
  }, [metadata, isLogged]);

  if (!loading) {
    return <LoggedContext.Provider value={value} {...props} />;
  }
  return (
    <Row>
      <Spin />
      <span>Cargando datos del paciente...</span>
    </Row>
  );
}
export function useLogged() {
  const context = React.useContext(LoggedContext);
  if (!context) {
    throw new Error("No se encontro Usuario en el contexto");
  }
  return context;
}
