import React from "react";
import { Control } from "./Control";
import { FormControlStyled } from "./styled";
import { IInputPayload } from "../../../../types/interfaces";
import { Controller, useFormContext } from "react-hook-form";
import { useEffect } from "react";
import { Typography } from "core/util-styled-components";
interface Props extends IInputPayload {
  orientation?: "row" | "column" | undefined;
  fluid?: boolean;
}
const ProcesarLabel = (label: string) =>
  label
    .replaceAll(/([a-z])([A-Z])/g, "$1 $2")
    .replaceAll("_", " ")
    .replaceAll("-", " ");
const FormControl = ({ orientation, ...rest }: Props) => {
  const { register } = useFormContext();
  useEffect(() => {
    register(rest.name, {
      required: typeof rest.required === "undefined" ? false : rest.required,
    });
    return () => {};
  }, []);

  return (
    <FormControlStyled
      orientation={orientation}
      fullWidth={rest.fullWidth}
      disabled={rest.disabled}
      width={rest.fluid ? "100%" : "auto"}
    >
      <strong className="label-input">
        <Typography variant="label" color="inherit">
          {ProcesarLabel(rest.name)} <strong> {rest.required && "*"} </strong>
        </Typography>
      </strong>
      <div className="content-input">
        <Controller
          name={rest.name}
          render={(hookForm) => (
            <Control type={rest.type} rest={rest} hookForm={hookForm} />
          )}
          defaultValue={rest.defaultValue}
        />
      </div>
    </FormControlStyled>
  );
};
export default FormControl;
