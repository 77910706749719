import React from "react";
import { Button } from "antd";
import FormControl from "../../Molecules/FormControl";
import { Column, Row, Heading } from "core/util-styled-components";
// import { FormProvider, useForm } from "react-hook-form";
import { IInputPayload } from "../../../../types/interfaces";
import { GridChild } from "core/util-styled-components/components/Grid";
import SelectorDisplays from "core/util-styled-components/components/SelectorDisplays";

const FormControls = ({ children, buttonProps, ...restProps }: any) => {
  return (
    <Column gap="10px">
      {restProps.inputs && (
        <Controls
          inputs={restProps.inputs}
          cols={restProps.cols}
          type={restProps.type}
        />
      )}
      {children}
      <Row
        style={{
          justifyContent: buttonProps?.justify || "flex-end",
          margin: "1em auto",
        }}
        gap="10px"
        width="100%"
      >
        <Button
          type={buttonProps?.submit?.type || "default"}
          onClick={restProps.onCancel ? restProps.onCancel : () => {}}
          {...buttonProps?.cancel}
        >
          {restProps.cancelLabel || "Cancelar"}
        </Button>
        <Button
          type={buttonProps?.submit?.type || "primary"}
          htmlType="submit"
          {...buttonProps?.submit}
        >
          {restProps.submitLabel || "Guardar"}
        </Button>
      </Row>
    </Column>
  );
};

FormControls.Extra = ({ children }: any) => <div>{children}</div>;

const Controls = ({ inputs, cols, type = "grid" }: any) => (
  <SelectorDisplays type={type} gap="30px" cols={cols}>
    {inputs
      .filter((_) => !_.disabled)
      .map((el: IInputPayload, key: number) => {
        if (el.type === "title-separator") {
          return (
            <GridChild
              span={cols}
              spanCol={el.customProps ? el.customProps.spanCol : undefined}
              spanRow={el.customProps ? el.customProps.spanRow : undefined}
            >
              <Heading variant="h3">{el.name}</Heading>
            </GridChild>
          );
        }
        if (el.type === "custom") {
          return (
            <GridChild
              span={cols}
              spanCol={el.customProps ? el.customProps.spanCol : undefined}
              spanRow={el.customProps ? el.customProps.spanRow : undefined}
            >
              <Column gap="10px">
                <Heading variant="h4">{el.name}</Heading>
                {el.customProps?.render}
              </Column>
            </GridChild>
          );
        }
        if (el.fullWidth) {
          return (
            <GridChild
              span={cols}
              spanCol={el.customProps ? el.customProps.spanCol : undefined}
              spanRow={el.customProps ? el.customProps.spanRow : undefined}
            >
              <FormControl
                fluid={type !== "grid"}
                key={key}
                {...el}
                orientation={"column"}
              />
            </GridChild>
          );
        }
        return (
          <GridChild
            span={el.fullWidth ? cols : 1}
            spanCol={el.customProps ? el.customProps.spanCol : undefined}
          >
            <FormControl
              fluid={type !== "grid"}
              key={key}
              {...el}
              orientation={"column"}
            />
          </GridChild>
        );
      })}
  </SelectorDisplays>
);

FormControls.Controls = Controls;

export default FormControls;
