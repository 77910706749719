import { Avatar, Button, Card } from "antd";
import ContentComponent from "components/skeleton/ContentComponent";
import { useLogged } from "core/hooks/LoggedContext";
// import { useSISContext } from "core/hooks/UNUSED_SISContext";
import { useMediaQueryCustom } from "core/hooks/useMediaQueryCustom";
// import { useUsuario } from "core/hooks/UNUSED_UsuarioContext";
import {
  Column,
  Div,
  Grid,
  Heading,
  Row,
  Typography,
} from "core/util-styled-components";
import styled from "styled-components";
import { Theme } from "theme/Theme";
const Portada = styled.div`
  background: linear-gradient(180deg, #32b2ff 0%, #deecf5 100%);
  height: 30vh;
`;

const MisDatosPersonales = () => {
  const { metadata, logout } = useLogged();

  // const { validacionSIS } = useSISContext();
  const { isTabletOrMobile } = useMediaQueryCustom();
  // const { logout } = useUsuario();
  return (
    <ContentComponent
      cover={<Portada style={{ maxHeight: "25vh", objectFit: "fill" }} />}
      bodyStyle={{
        padding: "0",
        overflowY: "auto",
      }}
    >
      {metadata?.DataSIGH && (
        <Row
          width="80%"
          margin="0 auto"
          justifyContent="space-between"
          responsiveReorder
        >
          <Row alignItems="flex-start" gap="10px">
            {/* <Avatar
              size={isTabletOrMobile ? 64 : 128}
              icon={<i className="ri-user-line" />}
              style={{
                marginTop: -64,
                backgroundColor: "#FFEDE2",
                color: Theme.colors.text,
                border: "3px solid white",
              }}
            /> */}
            <Column alignItems="flex-start">
              <Heading variant="h3">
                {metadata?.DataSIGH.nombres} {metadata?.DataSIGH.apaterno}{" "}
                {metadata?.DataSIGH.amaterno}
              </Heading>
              <Typography
                variant="label"
                style={{ fontWeight: 300 }}
                color="#0f0f0f"
              >
                Número de Historia Clínica:{" "}
                {metadata?.DataSIGH.NroHistoriaClinica}
              </Typography>
            </Column>
          </Row>
          <Button
            onClick={() => {
              logout();
            }}
          >
            <Row>
              <i className="ri-power-off-line" />
              <Typography style={{ marginLeft: "5px" }}>
                Cerrar sesión
              </Typography>
            </Row>
          </Button>
        </Row>
      )}

      <Div width="90%" margin="10px auto">
        <Card
          style={{ borderTopRightRadius: 0 }}
          bordered={false}
          title={
            <Typography
              color={Theme.colors.primary}
              style={{ fontWeight: "600" }}
            >
              Datos Personales registrados en sistema del hospital
            </Typography>
          }
        >
          <Grid cols={2}>
            <Typography>
              <strong style={{ fontWeight: "500" }}>DNI:</strong>{" "}
              {metadata?.DataSIGH.nroDocumento}
            </Typography>
            <Typography>
              <strong style={{ fontWeight: "500" }}>ID del Paciente:</strong>{" "}
              {metadata?.DataSIGH.IdPaciente}
            </Typography>

            <Typography>
              <strong style={{ fontWeight: "500" }}>Historia clinica:</strong>{" "}
              {metadata?.DataSIGH.NroHistoriaClinica}
            </Typography>
            <Typography>
              <strong style={{ fontWeight: "500" }}>
                Fecha de Nacimiento:
              </strong>{" "}
              {metadata?.DataSIGH.fechaNacimiento?.slice(0, 10)}
            </Typography>
          </Grid>
        </Card>
      </Div>

      {/* {validacionSIS &&
        validacionSIS.length > 0 &&
        validacionSIS.map((item) => (
          <Div width="90%" margin="20px auto">
            <Card
              style={{ borderTopRightRadius: 0 }}
              bordered={false}
              title={
                <Typography
                  color={Theme.colors.primary}
                  style={{ fontWeight: "600" }}
                >
                  Datos Personales registrados en el SIS
                </Typography>
              }
            >
              <Grid cols={2}>
                <Typography>
                  <strong style={{ fontWeight: "500" }}>
                    Nombre de afiliado:
                  </strong>{" "}
                  {item.Nombres} {item.ApePaterno} {item.ApeMaterno}
                </Typography>

                <Typography>
                  <strong style={{ fontWeight: "500" }}>Tipo formato:</strong>{" "}
                  {item.TipoFormato}
                </Typography>

                <Typography>
                  <strong style={{ fontWeight: "500" }}>N° Filiación:</strong>{" "}
                  {item.NroFiliacionInscripcion}
                </Typography>
                <Typography>
                  <strong style={{ fontWeight: "500" }}>
                    Plan beneficios:
                  </strong>{" "}
                  {item.PlanBeneficios}
                </Typography>
                <Typography>
                  <strong style={{ fontWeight: "500" }}>EESS:</strong>{" "}
                  {item.EESS}
                </Typography>
                <Typography>
                  <strong style={{ fontWeight: "500" }}>Ubicación EESS:</strong>{" "}
                  {item.UbicacionEESS}
                </Typography>
              </Grid>
            </Card>
          </Div>
        ))} */}
    </ContentComponent>
  );
};

export default MisDatosPersonales;
