import { useEffect } from "react";
import useAxios from "core/hooks/useAxios";
// import { useUsuario } from "core/hooks/UNUSED_UsuarioContext";
import { Column, Heading, Typography } from "core/util-styled-components";
import ContentComponent from "components/skeleton/ContentComponent";
import Informe from "./DXImagenes/components/Informe";
import { useLogged } from "core/hooks/LoggedContext";

const DiagnosticoImagenes = () => {
  const { metadata } = useLogged();

  const Informes = useAxios("POST", "/api/imagen/informe/por/paciente", true);

  useEffect(() => {
    if (metadata?.DataSIGH) {
      Informes.actualizarParametros({
        idpaciente: metadata?.DataSIGH?.IdPaciente,
      });
      Informes.iniciarPeticion();
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [metadata]);

  return (
    <ContentComponent title={<Heading variant="h2">DX por imagenes</Heading>}>
      <Typography style={{ margin: "20px 0" }}>
        Se muestran los resultados de los informes realizados por el equipo de
        diagnostico por imagenes
        {/* {validacionSIGH?.codigo !== 1 && (
          <Alert type="warning">
            Usted no tiene historia clinica en el hospital aún...
          </Alert>
        )} */}
      </Typography>

      <Heading variant="h3">Informes realizados</Heading>
      <Column gap="10px" style={{ marginTop: "10px" }}>
        {Informes.datos.map((_, i) => (
          <Informe _={_} />
        ))}
      </Column>
    </ContentComponent>
  );
};

export default DiagnosticoImagenes;
