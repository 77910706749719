import { Button } from "antd";
import Logo from "components/atoms/Logo";
import Usuario from "components/atoms/Usuario";
import RevisarTelefono from "components/molecules/RevisarTelefono";
import { useContext } from "core/hooks/Context";
import { useLogged } from "core/hooks/LoggedContext";
import { useMediaQueryCustom } from "core/hooks/useMediaQueryCustom";
// import { useUsuario } from "core/hooks/UNUSED_UsuarioContext";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import LateralBar from "./LateralBar";
import { ContainerGlobal, ContentLayout, Toolbar } from "./styled";

const Layout = ({ children }) => {
  const { isLogged } = useLogged();

  const nav = useNavigate();
  const { setBarActive, barActive } = useContext();
  const { isTabletOrMobile } = useMediaQueryCustom();

  useEffect(() => {
    if (!isLogged) {
      nav("/Login");
    }
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLogged]);

  return (
    <ContainerGlobal>
      <Toolbar>
        <Logo />
        {isTabletOrMobile && (
          <Button
            onClick={() => {
              setBarActive(!barActive);
            }}
          >
            <i className="ri-menu-3-line"></i>
          </Button>
        )}
        {!isTabletOrMobile && <Usuario />}
      </Toolbar>
      <ContentLayout>
        <LateralBar />
        {children}
      </ContentLayout>
      <RevisarTelefono />
    </ContainerGlobal>
  );
};

export default Layout;
