import { Alert, Button, notification } from "antd";
import Timer from "components/molecules/Timeout";
import ContentComponent from "components/skeleton/ContentComponent";
import { useRealTimeContext } from "core/hooks/RealTimeSocket";
import { useReferenciaRefcon } from "core/hooks/ReferenciaContext";
import useAxios from "core/hooks/useAxios";
import { useMediaQueryCustom } from "core/hooks/useMediaQueryCustom";
import time from "core/util-functions/src/core/time";
import { Column, Grid, Heading, Row, Typography } from "core/util-styled-components";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useLogged } from "core/hooks/LoggedContext";
import DiaCupo from "pages/ConsultaExterna/components/atoms/Cupo/DiaCupo";
import Cupo from "pages/ConsultaExterna/components/atoms/Cupo";

const ListaDisponibilidadCitas = () => {
  const { isTabletOrMobile } = useMediaQueryCustom();
  const [diaSeleccionado, setDiaSeleccionado] = useState(null);
  const [horaSeleccionado, setHoraSeleccionado] = useState(null);
  const [ocupadasCargadas, setOcupadasCargadas] = useState(false);
  const [mapDisponibilidad, setMapDisponibilidad] = useState([]);
  const { medicoSeleccionado, setMedicoSeleccionado, referenciaRefcon, restriccion_caso1 } = useReferenciaRefcon();
  const nav = useNavigate();

  const { metadata } = useLogged();

  const { socket } = useRealTimeContext();

  const ProgramacionBloqueadas = useAxios("GET", "/api/listar-programaciones-bloqueadas");
  const ListarDisponibilidadCupos = useAxios("POST", "/api/consulta-externa/cantidad-citas-programacion", false, {
    nombre: referenciaRefcon?.especialidad,
  });

  useEffect(() => {
    socket.on("user_joined_room", () => {
      console.log("user_joined_room");
    });

    socket.on("user_left_room", () => {
      console.log("user_left_room");
    });

    socket.on("mutation-on-list", () => {
      ProgramacionBloqueadas.recargarPeticion();

      console.log("mutation-on-list");
    });
    return () => {
      socket.off("user_joined_room");
      socket.off("user_left_room");
      socket.off("mutation-on-list");
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const GenerateCita = useAxios("POST", "/api/consulta-externa/v2/generar-cita/galenhos", true);

  const validaSiEstaLleno = (idProgramacion) => {
    const programacion = ListarDisponibilidadCupos.datos;

    const find = programacion.find((item) => item.IdProgramacion === idProgramacion);

    if (find) {
      return find.CantidadCitas >= find.totalCitas;
    }
    return false;
  };

  const Disponibilidad = useAxios("POST", "/api/consulta-externa/programacion-medica/medico", true);

  const ListarOcupadas = useAxios("POST", "/api/consulta-externa/programacion-medica/listar-ocupados", true);

  useEffect(() => {
    if (ListarOcupadas.respuestaServer === 200) {
      setOcupadasCargadas(true);
    } else {
      setOcupadasCargadas(false);
    }

    return () => {};
  }, [ListarOcupadas.respuestaServer]);

  useEffect(() => {
    diaSeleccionado && getHorasOcupadas(diaSeleccionado?.IdProgramacion);
    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [diaSeleccionado]);

  useEffect(() => {
    if (GenerateCita.respuestaServer === 200) {
      if (!Array.isArray(GenerateCita.datos) && GenerateCita.datos?.error) {
        return notification.error({
          message: GenerateCita.datos.message,
          placement: "topRight",
          duration: 2.5,
          key: "error",
        });
      }

      console.log(GenerateCita.datos);

      const __data = GenerateCita.datos.data[0];

      if (__data.idCita === 0) {
        return notification.error({
          message: "No se ha generado la cita porque el cupo se acaba de ocupar",
          placement: "topRight",
          duration: 2.5,
          key: "error",
        });
      } else {
        notification.success({
          message: "Cita generada exitosamente",
          placement: "topRight",
          duration: 2.5,
          key: "success",
        });
      }
      // leaveRoom();
      nav("/consulta-externa/mis-citas");
    }
    if (GenerateCita.respuestaServer === 500) {
      notification.error({
        message: "Error al generar cita",
        style: {
          fontSize: 10,
        },
        placement: "topRight",
        duration: 2.5,
        key: "error",
      });
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [GenerateCita.respuestaServer, GenerateCita.datos]);

  useEffect(() => {
    if (medicoSeleccionado) {
      Disponibilidad.actualizarParametros({
        idMedico: medicoSeleccionado?.IdMedico,
        idServicio: medicoSeleccionado?.IdServicio,
      });

      Disponibilidad.iniciarPeticion();
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [medicoSeleccionado]);

  useEffect(() => {
    if (!medicoSeleccionado) {
      nav("/consulta-externa/agendar-cita");
    }

    return () => {};
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [medicoSeleccionado]);

  const generarMapeoDisponibilidad = (inicio, fin, tiempoAñadir) => {
    const mapeo = [];
    let horaActual = inicio;

    // Calcula la diferencia en minutos entre inicio y fin
    const diferenciaMinutos = (new Date(`01/01/1999 ${fin}:00`).getTime() - new Date(`01/01/1999 ${inicio}:00`).getTime()) / (60 * 1000);

    // Calcula la cantidad de horarios a generar
    const cantidadHorarios = Math.ceil(diferenciaMinutos / tiempoAñadir);

    for (let i = 0; i < cantidadHorarios; i++) {
      const horaFin = time.sumarTiempo(horaActual, tiempoAñadir); // Calcula la hora de fin del intervalo
      mapeo.push({ hora: horaActual, horaFin }); // Agrega el intervalo al mapeo
      horaActual = horaFin; // Actualiza la hora actual para el siguiente intervalo
    }

    // Calcula la cantidad de horarios a mostrar (la mitad de los horarios disponibles)
    const cantidadHorariosMostrar = Math.ceil(mapeo.length / 2);

    // Obtiene los horarios a mostrar desde la mitad del mapeo hasta el final
    const horariosMostrar = mapeo.slice(-cantidadHorariosMostrar);

    return horariosMostrar;
  };

  const agendarCita = () => {
    const params = {
      IdProgramacion: diaSeleccionado?.IdProgramacion,
      nroDNI: metadata?.DataSIGH?.nroDocumento,
      idpaciente: metadata?.DataSIGH?.IdPaciente,
      cita: {
        fechaCita: diaSeleccionado?.Fecha,
        horaCita: horaSeleccionado?.hora,
        horaCitaFin: horaSeleccionado?.horaFin,
        referencia: referenciaRefcon?.nroreferencia,
      },
    };

    console.log(params);

    GenerateCita.actualizarParametros(params);
    GenerateCita.iniciarPeticion();
  };

  const getHorasOcupadas = (idProgramacion) => {
    ListarOcupadas.actualizarParametros({
      idProgramacion,
    });
    ListarOcupadas.iniciarPeticion();
  };

  // determinar si el boton de seleccion de hora esta deshabilitado
  const getDisabled = (item) => {
    if (!ocupadasCargadas) {
      return true;
    }

    if (!Array.isArray(ListarOcupadas.datos)) {
      return true;
    }

    const ocupadas = ListarOcupadas.datos?.filter((ocupada) => ocupada.HoraInicio === item.hora && ocupada.HoraFin === item.horaFin);

    return ocupadas.length > 0;
  };

  const getInSelection = (id) => {
    if (!Array.isArray(ProgramacionBloqueadas.datos)) {
      return false;
    }

    if (diaSeleccionado?.IdProgramacion === id) {
      return false;
    }

    const filter = ProgramacionBloqueadas.datos.filter((el) => el.IdProgramacion === id);

    return filter.length > 0;
  };

  const isBloquedCase1 = (item) => {
    if (!restriccion_caso1) {
      return false;
    }
    // item.Fecha is '2023-05-25T00:00:00.000Z'
    // si el mes de item.Fecha es igual al mes actual retorna true
    // Crear una nueva fecha para obtener el mes actual
    const fechaActual = new Date();

    // Obtener el mes actual de la fecha actual
    const mesActual = fechaActual.getMonth();

    // Crear una nueva fecha a partir de la cadena "2023-05-25T00:00:00.000Z"
    const fechaItem = new Date(item.Fecha);

    // Obtener el mes de la fecha "fechaItem"
    const mesItem = fechaItem.getMonth();

    // Comparar si los meses son iguales y retornar true o false
    return mesItem === mesActual;
  };

  useEffect(() => {
    if (diaSeleccionado) {
      setMapDisponibilidad(generarMapeoDisponibilidad(diaSeleccionado?.HoraInicio, diaSeleccionado?.HoraFinProgramacion, diaSeleccionado?.TiempoPromedioAtencion));
    } else {
      setMapDisponibilidad([]);
    }

    return () => {};
  }, [diaSeleccionado]);

  return (
    <ContentComponent>
      <Column gap="15px">
        <Row>
          <Button
            type="text"
            onClick={() => {
              // leaveRoom();
              setMedicoSeleccionado(null);
            }}
          >
            <i className="ri ri-arrow-left-line"></i>
          </Button>
          <Heading variant="h3" style={{ margin: 0 }}>
            Programacion médica
          </Heading>
        </Row>

        <Typography variant="h3">Seleccione el día en el que desea agendar su cita</Typography>

        <Typography>
          Agendar una cita con Dr(a) <b>{medicoSeleccionado?.medico}</b> - {medicoSeleccionado?.Servicio}
        </Typography>
        <Column gap="15px" style={{ height: "55vh", overflowY: "auto", overflowX: "hidden" }}>
          <Typography>Seleccione el día en el que desea agendar su cita</Typography>
          {restriccion_caso1 && (
            <Alert
              type="warning"
              banner
              message={
                <Typography color="inherit">
                  Usted tiene una cita para este mes con esta especialidad, pero hay disponibilidad para el siguiente mes, así que aquí se le muestran los días que puede escoger para la cita.
                </Typography>
              }
            ></Alert>
          )}
          <Grid cols={isTabletOrMobile ? 2 : 4} gap="10px">
            {Array.isArray(Disponibilidad.datos) &&
              Disponibilidad.datos.map((item, index) => {
                return (
                  <DiaCupo
                    item={item}
                    value={diaSeleccionado}
                    onChange={() => {
                      if (diaSeleccionado) {
                        // desbloquearProgramacion(diaSeleccionado.IdProgramacion);
                      }
                      setDiaSeleccionado(item);
                      // bloquearProgramacion(item.IdProgramacion);
                      ProgramacionBloqueadas.recargarPeticion();
                      setHoraSeleccionado(null);
                    }}
                    isBloqued={getInSelection(item.IdProgramacion) || isBloquedCase1(item)}
                    isCollapse={validaSiEstaLleno(item.IdProgramacion)}
                  />
                );
              })}
          </Grid>
          {diaSeleccionado && (
            <Column justifyContent="space-between">
              <Typography>
                Seleccione el cupo en el que desea agendar su cita para el turno de <b>{diaSeleccionado?.HoraInicio}</b> a <b>{diaSeleccionado?.HoraFin}</b> en <b>{diaSeleccionado?.Turno}</b>
              </Typography>

              <Heading>
                Tiempo restante para seleccionar el cupo{" "}
                <Timer
                  onTimerComplete={() => {
                    if (diaSeleccionado) {
                      // desbloquearProgramacion(diaSeleccionado.IdProgramacion);
                      setDiaSeleccionado(null);
                      ProgramacionBloqueadas.recargarPeticion();
                    }
                  }}
                />
              </Heading>
            </Column>
          )}
          <Grid cols={isTabletOrMobile ? 1 : 4} gap="10px">
            {mapDisponibilidad.map((item, index) => {
              return <Cupo diaSeleccionado={diaSeleccionado} item={item} value={horaSeleccionado} onChange={() => setHoraSeleccionado(item)} isDisabled={getDisabled(item)} />;
            })}
          </Grid>
        </Column>
        <Row justifyContent="space-between">
          <Typography variant="label">Por favor, tenga en cuenta que la cita se agendará para el día y hora que usted seleccione.</Typography>
          <Button
            type="primary"
            disabled={horaSeleccionado === null}
            onClick={() => {
              agendarCita();
            }}
          >
            Guardar cita
          </Button>
        </Row>
      </Column>
    </ContentComponent>
  );
};

export default ListaDisponibilidadCitas;
