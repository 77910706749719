import styled from "styled-components";
import {
  IHeading,
  ITypografy,
  T_Heading,
  T_Typografy,
} from "../types/Interfaces";

const sizes = {
  h1: 30,
  h2: 22,
  h3: 18,
  h4: 16,
  h5: 10,
  h6: 8,
  span: 14,
  p: 15,
  label: 12,
};

const sizesResponsive = {
  h1: 15,
  h2: 11,
  h3: 9,
  h4: 8,
  h5: 5,
  h6: 4,
  span: 7,
  p: 7.5,
  label: 6,
};

const calcularFontSize = (
  variant?: T_Typografy | T_Heading,
  responsive?: boolean
) => {
  let size = 14;

  const sizeVariant = responsive ? sizesResponsive : sizes;

  variant && (size = sizeVariant[variant] ? sizeVariant[variant] : 14);
  return `${size}px`;
};

export const Heading = styled.h1<IHeading>`
  font-size: ${(props: IHeading) => calcularFontSize(props.variant)};
  font-family: ${(props: IHeading) =>
    props.secondary
      ? props.theme.fonts.fontSecondaryFamily
      : props.theme.fonts.fontFamily};
  color: ${(props) => props.color || props.theme.colors.text};
  text-align: ${(props: IHeading) => (props.center ? "center" : "inherit")};
  margin-bottom: 0;
  font-weigth: ${(props: IHeading) => (props.fw ? props.fw : "bold")};
`;

export const Typography = styled.span<ITypografy>`
  color: ${(props) => props.color || props.theme.colors.text || "inherit"};
  font-family: ${(props) => props.theme.fonts.fontFamily};
  font-size: ${(props: ITypografy) => calcularFontSize(props.variant)};
  font-weigth: ${(props: ITypografy) => (props.fw ? props.fw : "normal")};
  /*
    font-size responsive
   */
  @media (max-width: 768px) {
    font-size: ${(props: ITypografy) =>
      calcularFontSize(props.variant ? props.variant : "span")};
  }
`;

export const Label = styled.label`
  color: ${(props) => props.theme.colors.text};
  font-family: ${(props) => props.theme.fonts.fontFamily};
  font-size: 14px;
`;
