import { Button } from "antd";
import { Heading } from "core/util-styled-components";
import { getHora } from "pages/ConsultaExterna/utils/ce_util";
import React, { useEffect, useState } from "react";
import { Theme } from "theme/Theme";

const Cupo = ({
  diaSeleccionado,
  item,
  isDisabled,
  value,
  onChange,
  defaultHours = null,
}) => {
  const [hora, setHora] = useState("");

  useEffect(() => {
    setHora(getHora(diaSeleccionado?.Turno, diaSeleccionado?.HoraInicio));

    return () => {};
  }, [diaSeleccionado]);

  return (
    <Button
      onClick={onChange}
      disabled={isDisabled}
      style={{
        color: isDisabled ? "#939393" : Theme.colors.primary,
        borderColor:
          Theme.colors.primary + (value?.hora === item.hora ? "FF" : "13"),
        backgroundColor: (isDisabled ? "#939393" : Theme.colors.primary) + "13",
      }}
    >
      <Heading color="inherit" style={{ margin: 0 }}>
        Turno{" "}
        {isDisabled ? "Ocupado " : "libre (" + (defaultHours ?? hora) + ")"}
      </Heading>
    </Button>
  );
};

export default Cupo;
