import React from "react";
import { Input } from "antd";
import { GeneralControlProps } from "../../../../types/interfaces";

const InputControl = ({ rest, hookForm }: GeneralControlProps) => {
  return (
    <div>
      <Input
        onChange={(e) => {
          rest.onChange && rest.onChange(e);
          if (hookForm.field) {
            hookForm.field.onChange(e);
          } else {
            hookForm.onChange(e);
          }
        }}
        defaultValue={rest.defaultValue}
        {...rest.customProps}
      />
    </div>
  );
};

export default InputControl;
