import React from "react";
import { notification, Spin } from "antd";

export const axiosNotification = (
  cargando,
  respuestaServer,
  msgs = {
    cargando: "Cargando...",
    correcto: "Carga exitosa",
    error: "Error en la carga",
  }
) => {
  if (cargando) {
    notification.destroy("info");
    notification.info({
      style: {
        fontSize: 10,
      },
      message: msgs["cargando"],
      icon: <Spin />,
      placement: "topRight",
      duration: 0,
      key: "info",
    });
  } else {
    notification.destroy("info");
    if (respuestaServer === 200) {
      notification.destroy("success");
      notification.success({
        message: msgs["correcto"],
        placement: "topRight",
        duration: 2.5,
        key: "success",
      });
    }

    if (respuestaServer === 400) {
      notification.destroy("error");
      notification.error({
        message: msgs["error"],
        style: {
          fontSize: 10,
        },
        placement: "topRight",
        duration: 2.5,
        key: "error",
      });
    }
  }
};
