import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import "remixicon/fonts/remixicon.css";
import { Theme } from "theme/Theme";
import { ThemeProvider } from "styled-components";
// import { UsuarioProvider } from "core/hooks/UNUSED_UsuarioContext";
import { ReferenciaProvider } from "core/hooks/ReferenciaContext";
// import { SISProvider } from "core/hooks/UNUSED_SISContext";
import { RealTimeProvider } from "core/hooks/RealTimeSocket";
import { Provider } from "core/hooks/Context";
import { LoggedProvider } from "core/hooks/LoggedContext";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <LoggedProvider>
      {/* <UsuarioProvider> */}
      {/* <SISProvider> */}
      <ReferenciaProvider>
        <RealTimeProvider>
          <ThemeProvider theme={Theme}>
            <Provider>
              <App />
            </Provider>
          </ThemeProvider>
        </RealTimeProvider>
      </ReferenciaProvider>
      {/* </SISProvider> */}
      {/* </UsuarioProvider> */}
    </LoggedProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
