import { Column, Heading, Typography } from "core/util-styled-components";
import React from "react";

const NoDisponibleView = () => {
  return (
    <Column
      alignItems="center"
      justifyContent="center"
      style={{
        height: "80vh",
        boxShadow: "0 2px 4px rgba(0,0,0,0.1)",
        backgroundColor: "#fff",
      }}
    >
      <Heading
        variant="h1"
        color="#ff4d4f"
        style={{ marginBottom: "10px", fontSize: "32px" }}
      >
        Servicio no disponible
      </Heading>
      <Typography color="#595959" style={{ fontSize: "18px" }}>
        Vuelve a intentarlo después del lunes a las 7:00 AM.
      </Typography>
    </Column>
  );
};

export default NoDisponibleView;
