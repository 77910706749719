import { Button, Input } from "antd";
import { Content } from "components/skeleton/styled";
import React, { useState } from "react";

const TestIO = () => {
  const [input, setInput] = useState("");

  const [conexiones, setConexiones] = useState([]);

  return (
    <Content>
      <Input
        value={input}
        onChange={({ target }) => {
          setInput(target.value);
        }}
      />
      <Button onClick={() => {}}>Join Room</Button>

      {conexiones.map((item, idx) => {
        return (
          <div key={item.id}>
            Paciente en espera {idx + 1} {item.Status}
          </div>
        );
      })}
    </Content>
  );
};

export default TestIO;
